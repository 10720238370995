import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  AlertController,
  LoadingController,
  ModalController
} from "@ionic/angular";
import { FormlyFormOptions } from "@ngx-formly/core";
import { FormsProvider } from "src/app/ht-client-core/common/services/forms/forms";
import { ReservationForm } from "../../../common/models/reservation-form";
import { ReservationService } from "../../services/reservation.service";
import { TimeSlot } from "../../../common/models/time-slot";
import { TranslateService } from "@ngx-translate/core";
import { UtilsService } from "src/app/ht-client-core/common/services/utils/utils.service";
import { environment } from "src/environments/environment";
import {
  AnalyticsService,
  EVENTORIGIN,
  EVENTTYPES
} from "src/app/ht-client-core/common/services/analytics/analytics.service";
import { HotelService } from "src/app/ht-client-core/common/services/hotel/hotel.service";
@Component({
  selector: "app-reservation-overview",
  templateUrl: "./reservation-overview.page.html",
  styleUrls: ["./reservation-overview.page.scss"]
})
export class ReservationOverviewPage implements OnInit {
  @Input() reservationForm!: ReservationForm;
  @Input() type!: string;
  @Input() resId!: string;
  @Input() selectedSlot!: TimeSlot;
  @Input() selectedValue!: Number;
  fields?: any;
  description = "";
  form?: FormGroup;
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      disabled: true
    }
  };
  formLoaded: boolean = false;
  locale?: string;
  timezone?: string;
  constructor(
    private forms: FormsProvider,
    public modalController: ModalController,
    private reservationsService: ReservationService,
    private translateService: TranslateService,
    private utilities: UtilsService,
    private loadingController: LoadingController,
    private alertCtrl: AlertController,
    private analytics: AnalyticsService,
    private hotelService: HotelService
  ) {}

  ngOnInit() {
    this.form = new FormGroup({});
    this.locale = this.translateService.getDefaultLang();
    this.timezone = environment.timezone;

    if (this.reservationForm) {
      if (this.reservationForm["fields"]) {
        this.reservationForm["groups"].forEach((element) => {
          this.model[element.id] = [];
          if (!!element.repeatability)
            for (let i = 0; i < element.repeatability; i++) {
              this.model[element.id].push({});
            }
        });

        this.reservationForm["fields"].forEach((element) => {
          if (element.group) {
            const group =
              this.reservationForm["groups"][
                this.utilities.getObjFromId(
                  this.reservationForm["groups"],
                  element.groupId
                )
              ];
            if (element.value) {
              let range =
                group.repeatability !== -1
                  ? group.repeatability
                  : element.value.length;
              if (element.groupId)
                for (let i = 0; i < range; i++) {
                  if (this.model[element.groupId][i] === undefined) {
                    // curModel[element.groupId][i] = {};
                    this.model[element.groupId].push({});
                  }
                }
            }
          }
        });
        this.fields = this.forms.getFormlyFields(
          this.reservationForm["fields"],
          this.reservationForm["groups"]
        );

        // console.log(this.fields);
        this.formLoaded = true;
      }
    }
  }

  async onSubmit() {
    if (this.form && this.selectedSlot) {
      const alertCtrl = await this.alertCtrl.create({
        header: this.translateService.instant(
          "RESERVATIONS.PAID_WARNING_HEADER"
        ),
        message: this.translateService.instant(
          "RESERVATIONS.PAID_WARNING_MESSAGE"
        ),
        buttons: [
          {
            text: this.translateService.instant("RESERVATIONS.CONFIRMATION_NO"),
            role: "cancel"
          },
          {
            text: this.translateService.instant(
              "RESERVATIONS.CONFIRMATION_YES"
            ),
            role: "confirm",
            handler: () => {
              this.submitReservation();
            }
          }
        ]
      });

      if (this.selectedSlot.entitled) {
        this.submitReservation();
      } else {
        alertCtrl.present();
      }
    } else {
      // loading.dismiss();
    }
  }

  async submitReservation() {
    let loading = await this.loadingController.create({
      cssClass: "loading-class",
      message: this.translateService.instant("RESERVATIONS.SENDING")
    });
    loading.present();

    this.form.markAllAsTouched();

    this.reservationsService
      .submit(
        this.type,
        this.resId,
        this.selectedSlot.startsAt,
        this.selectedSlot.endsAt,
        this.selectedValue,
        this.description,
        this.reservationForm
          ? this.forms.prepareForm(this.reservationForm, this.form.value)
          : null
      )
      .subscribe(
        (res) => {
          this.fields = [];
          if (this.reservationForm && this.options.resetModel)
            this.options.resetModel();
          // console.log(res);
          loading.dismiss();
          this.modalController.dismiss({ success: true });

          this.analytics.logEvent(EVENTTYPES.SUBMIT_RESERVATION, {
            id: this.resId,
            hotelId: this.hotelService.hotel ? this.hotelService.hotel.id : "",
            origin: EVENTORIGIN.RESERVATION_PAGE
          });
        },
        async (err) => {
          loading.dismiss();
          console.log(err);
          this.form = await this.forms.handleErrors(err, this.form);
        }
      );
  }
}
