import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-tooltip-message",
  templateUrl: "./tooltip-message.component.html",
  styleUrls: ["./tooltip-message.component.scss"],
})
export class TooltipMessageComponent implements OnInit {
  @Input() hint: string;
  constructor() {}

  ngOnInit() {}
}
