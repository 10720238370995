import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { RoomPanelPageRoutingModule } from "./room-panel-routing.module";

import { RoomPanelPage } from "./room-panel.page";
import { TranslateModule } from "@ngx-translate/core";
import { MatSliderModule } from "@angular/material/slider";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    MatSliderModule,
    RoomPanelPageRoutingModule
  ],
  declarations: [RoomPanelPage],
  entryComponents: [RoomPanelPage]
})
export class RoomPanelPageModule {}
