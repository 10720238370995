import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { FieldType, FormlyField } from "@ngx-formly/core";
import { Observable } from "rxjs";

@Component({
  selector: "app-custom-select",
  templateUrl: "./custom-select.component.html",
  styleUrls: ["./custom-select.component.scss"]
})
export class CustomSelectComponent extends FieldType {
  showSelect = false;
  currentFields: any[] | Observable<any[]> = [];
  @Input() field;
  searchTerm = "";
  constructor(private popoverCtrl: PopoverController) {
    super();
  }
  checkIfChecked(array: string[], entity) {
    let item;
    // console.log('array = ', array);
    // console.log('entity = ', entity.value)
    if (array) {
      if (!Array.isArray(array)) {
        array = [array];
      }
      array.forEach((subfield, key) => {
        if (subfield === entity.value) {
          item = key;
          entity.checked = true;
        } else {
          entity.checked = false;
        }
      });
    }
    return item;
  }

  checkOption(array: string[], entity) {
    let chk = this.checkIfChecked(array, entity);
    // console.log('called checkOption');
    if (chk == undefined) {
      if (!array) {
        array = [];
      }
      if (!Array.isArray(array)) {
        array = [array];
      }
      array.push(entity);
    } else {
      array.splice(chk, 1);
    }
    console.log(array);
    return array;
  }

  dismiss(selectedOption) {
    this.popoverCtrl.dismiss(selectedOption);
  }
}
