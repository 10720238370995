import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CacheService } from "ionic-cache";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class WeatherService {
  appId;
  url;
  constructor(private cache: CacheService, private http: HttpClient) {
    this.appId = environment.weather.app_id;
    this.url = environment.weather.endpoint;
  }

  getTodaysWeatherByName(area) {
    // console.log("Weather service at " + area);
    return new Promise((resolve, reject) => {
      if (this.cache.isOnline) {
        this.cache.clearGroup(
          this.url +
            "/weather" +
            area +
            "&appid=" +
            this.appId +
            "&units=metric"
        );
      }
      this.cache
        .loadFromObservable(
          this.url +
            "/weather" +
            area +
            "&appid=" +
            this.appId +
            "&units=metric",
          this.getData(this.url + "/weather", area)
        )
        .pipe(
          map((results) => {
            // console.log(results);
            results.image = this.filterIcon(results);
            results.ion_icon = this.filterIonicIcon(results);
            return results;
          })
        )
        .subscribe(
          (res) => resolve(res),
          (err) => {
            // console.log(err);
            reject(err);
          }
        );
    });
  }

  getForecastByName(area) {
    // console.log("Forecast service at " + area);
    return new Promise((resolve, reject) => {
      if (this.cache.isOnline) {
        this.cache.clearGroup(
          this.url +
            "/forecast/daily" +
            area +
            "&appid=" +
            this.appId +
            "&units=metric"
        );
      }
      this.cache
        .loadFromObservable(
          this.url +
            "/forecast/daily" +
            area +
            "&appid=" +
            this.appId +
            "&units=metric",
          this.getData(this.url + "/forecast/daily", area, 15)
        )
        .pipe(
          map((results) => {
            // console.log(results);
            results["image"] = this.filterIcon(results);
            results["ion_icon"] = this.filterIonicIcon(results);
            return results;
          })
        )
        .subscribe(
          (res) => resolve(res),
          (err) => {
            // console.log(err);
            reject(err);
          }
        );
    });
  }

  getForecastByLocation(lat, lng) {
    // console.log('Forecast service at ' + area);
    return new Promise((resolve, reject) => {
      if (this.cache.isOnline) {
        this.cache.clearGroup(
          this.url +
            "/onecall" +
            lat +
            lng +
            "&appid=" +
            this.appId +
            "&units=metric"
        );
      }
      this.cache
        .loadFromObservable(
          this.url +
            "/onecall" +
            lat +
            lng +
            "&appid=" +
            this.appId +
            "&units=metric",
          this.getDataByCoords(this.url + "/onecall", lat, lng)
        )
        .pipe(
          map((results) => {
            results = results["daily"];
            // console.log(results);
            results.forEach((day) => {
              // day['image'] = this.filterIcon(day);
              day["ion_icon"] = this.filterIonicIcon(day);
            });
            return results;
          })
        )
        .subscribe(
          (res) => resolve(res),
          (err) => {
            // console.log(err);
            reject(err);
          }
        );
    });
  }

  getData(url: string, area: string, cnt: Number = null) {
    let parameters = {
      q: area,
      appid: this.appId,
      units: "metric"
    };
    if (cnt) parameters["cnt"] = cnt;
    let request = this.http.get(url, {
      params: parameters
    });
    return request;
  }

  getDataByCoords(url: string, lat: string, lng: string, cnt: Number = null) {
    let parameters = {
      lat: lat,
      lon: lng,
      appid: this.appId,
      exclude: ["hourly", "monthly"],
      units: "metric"
    };
    if (cnt) parameters["cnt"] = cnt;
    let request = this.http.get(url, {
      params: parameters
    });
    return request;
  }

  filterIcon(data) {
    let code = data.weather[0].icon.substring(0, 2);
    if (code == "01" || code == "50" || code == "02") {
      return "menu_icon-weather-" + data.weather[0].icon;
    } else {
      return "menu_icon-weather-" + code;
    }
  }

  filterIonicIcon(data) {
    let code = data.weather[0].icon.substring(0, 2);
    let weatherIconDict = {
      "01d": "sunny",
      "01n": "moon",
      "02d": "partly-cloudy",
      "02n": "cloudy-night",
      "03d": "cloudy",
      "03n": "cloudy",
      "04d": "cloudy",
      "04n": "cloudy",
      "09d": "rainy",
      "09n": "rainy",
      "10d": "rainy",
      "10n": "rainy",
      "11d": "thunderstorm",
      "11n": "thunderstorm",
      "12d": "snow",
      "12n": "snow",
      "13d": "cloud-outline",
      "13n": "cloud-outline"
    };

    // if (code == '01' || code == '50' || code == '02') {
    //   return 'assets/img/weather-' + data.weather[0].icon + '.png';
    // } else {
    //   return 'assets/img/weather-' + code + '.png';
    // }
    return weatherIconDict[data.weather[0].icon];
  }
}
