import { ApiService } from "../../common/services/api/api.service";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Business } from "../../common/models/business";
import { AuthenticationService } from "../../common/services/authentication/authentication.service";
import { ReservationHistoryItem } from "../../common/models/reservation-history-item";
import * as moment from "moment-timezone";
import { Reservation } from "../../common/models/reservation";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root"
})
export class ReservationService {
  private historyUpdated = new Subject<any>();
  timezone = environment.timezone;
  constructor(
    private api: ApiService,
    private authService: AuthenticationService
  ) {}

  getHistoryUpdateListener() {
    return this.historyUpdated.asObservable();
  }

  triggerHistoryChange() {
    this.historyUpdated.next(true);
  }

  getAll(): Observable<Reservation[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/reservables/pages",
        {
          include: "reservationForm,knxServers"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          res["data"].forEach((element) => {
            element.reservationType = "pages";
          });
          if (!!this.authService.user) {
            res["data"] = this.filterVIP(
              res["data"],
              this.authService.booking.room
                ? this.authService.booking.room.id
                : null
            );
          } else {
            res["data"] = this.filterVIP(res["data"], null);
          }
          return res["data"];
        })
      );
  }

  getActivityReservations(): Observable<Reservation[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/reservables/activities",
        {
          include: "reservationForm,knxServers"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          res["data"].forEach((element) => {
            element.reservationType = "activities";
          });
          if (!!this.authService.user) {
            res["data"] = this.filterVIP(
              res["data"],
              this.authService.booking.room
                ? this.authService.booking.room.id
                : null
            );
          } else {
            res["data"] = this.filterVIP(res["data"], null);
          }
          return res["data"];
        })
      );
  }

  getById(id): Observable<Reservation> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/reservables/pages/" + id,
        {
          include: "reservationForm"
        },
        null,
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getBusinesses(): Observable<Business[]> {
    return this.api
      .get(
        "pois/" + this.authService.getHotelId() + "/businesses/pois",
        {},
        null,
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getSlots(pageId, type, startDate, endDate) {
    // console.log("Asked for slots");
    return this.api
      .get(
        "hotels/" +
          this.authService.getHotelId() +
          "/reservables/" +
          type +
          "/" +
          pageId +
          "/slots",
        {
          "filters[start]": startDate + "T00:00:00Z",
          "filters[end]": endDate + "T00:00:00Z"
        },
        null,
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getPersonalizedSlots(pageId, type, startDate, endDate) {
    // console.log("Asked for slots");
    return this.api
      .get(
        "hotels/" +
          this.authService.getHotelId() +
          "/reservables/" +
          type +
          "/" +
          pageId +
          "/personalizedSlots",
        {
          "filters[start]": startDate + "T00:00:00Z",
          "filters[end]": endDate + "T00:00:00Z"
        },
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        },
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getReservationHistory(
    type: string = "pages",
    page: string = "1"
  ): Observable<{ data: ReservationHistoryItem[]; meta: any }> {
    return this.api
      .get(
        "hotels/" +
          this.authService.getHotelId() +
          "/reservables/" +
          type +
          "/reservations",
        {
          include:
            "reservable,reservable.reservationForm,reservable.knxServers",
          page: page
        },
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        }
      )
      .pipe(
        map((res) => {
          res["data"].forEach((element) => {
            element.reservable.reservationType = type;
          });
          return res;
        })
      );
  }

  getUpcomingReservations(
    type: string = "pages"
  ): Observable<{ data: ReservationHistoryItem[]; meta: any }> {
    return this.getReservationHistory(type).pipe(
      map((data) => {
        // const indexDate = moment(
        //   new Date(data)
        // ).tz(this.timezone);
        const nowDate = moment().tz(this.timezone);
        data.data = data.data.filter((a) =>
          nowDate.isBetween(
            moment(
              new Date(a.start).setTime(new Date(a.start).getTime() - 1800000)
            ).tz(this.timezone),
            moment(new Date(a.end)).tz(this.timezone)
          )
        );
        // data.data.forEach((res) => {
        //   console.log(res);
        // });
        return data;
      })
    );
  }

  /***
   * Type = reservable type
   * id = reservable id
   * start = start time
   * end = end time
   * count = slots
   * description = description
   * input = form input
   ***/
  submit(type, id, start, end, count, description, input) {
    return this.api.post(
      "hotels/" +
        this.authService.getHotelId() +
        "/reservables/" +
        type +
        "/" +
        id +
        "/reservations",
      {
        start: start.toISOString(true).replace(".000Z", "Z"),
        end: end.toISOString(true).replace(".000Z", "Z"),
        count: count,
        description: description,
        form_input: input
      },
      {
        headers: {
          Authorization: "Bearer " + this.authService.getToken()
        }
      }
    );
  }

  cancelReservation(type, reservationId) {
    return this.api.delete(
      `hotels/${this.authService.getHotelId()}/reservables/${type}/reservations/${reservationId}`,
      {
        headers: {
          Authorization: "Bearer " + this.authService.getToken()
        }
      }
    );
  }

  filterVIP(pages, room) {
    if (!room) {
      pages = pages.filter((a) => {
        if (a.reservability_room_ids) {
          return a.reservability_room_ids.length < 1;
        } else {
          return true;
        }
      });
    } else {
      pages = pages.filter((a) => {
        if (!a.reservability_room_ids) {
          return true;
        }
        return (
          a.reservability_room_ids.length < 1 ||
          a.reservability_room_ids.indexOf(room) !== -1
        );
      });
    }
    return pages;
  }
}
