import { Component, Input, OnInit } from "@angular/core";
import { ActivityCategory } from "src/app/ht-client-core/common/models/activity-category";
import { Space } from "src/app/ht-client-core/common/models/space";

@Component({
  selector: "app-activity-alert",
  templateUrl: "./activity-alert.page.html",
  styleUrls: ["./activity-alert.page.scss"]
})
export class ActivityAlertPage implements OnInit {
  @Input() activityId: string;
  @Input() title: string;
  @Input() message: string;
  @Input() space: Space;
  @Input() category: ActivityCategory;
  @Input() image: string;
  constructor() {}

  ngOnInit() {}
}
