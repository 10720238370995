import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";

import {
  AlertController,
  Animation,
  AnimationController,
  ModalController,
  Platform,
  PopoverController,
  ToastController
} from "@ionic/angular";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment-timezone";
import { NavigationExtras, Router } from "@angular/router";
import { Page } from "./ht-client-core/common/models/page";
import { HotelService } from "./ht-client-core/common/services/hotel/hotel.service";
import { ReservationPage } from "./ht-client-core/reservationsModule/pages/reservation/reservation.page";
import { AuthenticationService } from "./ht-client-core/common/services/authentication/authentication.service";
import { PushService } from "./ht-client-core/common/services/push/push.service";
import { App } from "@capacitor/app";
import { environment } from "src/environments/environment";
import { Device } from "@capacitor/device";
import localeEl from "@angular/common/locales/el";
import localeElExtra from "@angular/common/locales/extra/el";
import localeDe from "@angular/common/locales/el";
import localeDeExtra from "@angular/common/locales/extra/de";
import { Location, registerLocaleData } from "@angular/common";
import { ApiService } from "./ht-client-core/common/services/api/api.service";
import { UiSetupService } from "./ht-client-core/common/services/ui-setup/ui-setup.service";
import { ReservationService } from "./ht-client-core/reservationsModule/services/reservation.service";
import { ServicesService } from "./ht-client-core/servicesModule/services/services.service";
import { OrderService } from "./ht-client-core/ordersModule/services/order/order.service";
import { HttpParams } from "@angular/common/http";
import { LanguageService } from "./ht-client-core/common/services/language/language.service";
import { LocalNotifications } from "@capacitor/local-notifications";
import { ActivityAlertPage } from "./ht-client-core/activitiesModule/pages/activity-alert/activity-alert.page";
import {
  AnalyticsService,
  EVENTORIGIN,
  EVENTTYPES
} from "./ht-client-core/common/services/analytics/analytics.service";
import { UtilsService } from "./ht-client-core/common/services/utils/utils.service";
import { Subscription } from "rxjs";
import { Preferences } from "@capacitor/preferences";
import { CacheService } from "ionic-cache";
import { QuestionnairesService } from "./ht-client-core/questionnairesModule/services/questionnaires.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  public selectedIndex = 0;
  @ViewChild("maincontent", { static: false }) menu: ElementRef;
  @ViewChild("blob", { static: false }) blob: ElementRef;
  @ViewChild("guestapp") guestapp: any;
  favIcon: HTMLLinkElement = document.querySelector("#appIcon");

  public showSplash = true;

  // public pages: Observable<Page[]>
  public pages: Page[];
  private hotel: string;
  public dark = false;
  private gaEnabled: boolean = false;

  languageSub: Subscription;
  hotelSub: Subscription;
  authSub: Subscription;
  splashAnimation: Animation;
  waveAnimation: Animation;
  endAnimation: Animation;
  logoAnimation: Animation;
  urlParams;
  user;
  booking;
  applogo;
  wideLogo = false;
  toggleDarkModeHandler = (force) => {
    document.body.classList.toggle("dark", force);
    this.UISetupService.themeChanged(force);
  };
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private router: Router,
    private hotelService: HotelService,
    private modalController: ModalController,
    private zone: NgZone,
    private pushService: PushService,
    private alertCtrl: AlertController,
    private authService: AuthenticationService,
    private animationCtrl: AnimationController,
    private location: Location,
    private api: ApiService,
    private UISetupService: UiSetupService,
    private reservationService: ReservationService,
    private servicesService: ServicesService,
    private orderService: OrderService,
    private toastCtrl: ToastController,
    private uiSetupService: UiSetupService,
    private langService: LanguageService,
    private popoverController: PopoverController,
    private analyticsService: AnalyticsService,
    private utilService: UtilsService,
    private cache: CacheService,
    private questionnaireService: QuestionnairesService,
    private title: Title
  ) {}

  async loadUI() {
    await this.UISetupService.loadUISettings();
  }

  async initializeURLParams() {
    const path = this.location.path(true),
      hasParams = /\?(.+?\=.+){1}/;
    let params;
    if (hasParams.test(path)) {
      params = {};
      path
        .split("?")[1]
        .split("&")
        .forEach((both) => {
          let e = both.split("=");
          params[e[0]] = e[1];
        });
    }

    this.urlParams = params;
    // console.log("PATH SELECTED", path);
    // console.log("URLPARAMS", this.urlParams);

    await this.authService.loadToken();
    let token = this.authService.getToken();
    // console.log("the hotel id is", this.authService.getHotelId());
    if (this.authService.getHotelId()) {
      await this.hotelService.getHotel(true);
    }
    // console.log("my token ", token);
    if (!!token) {
      this.booking = await this.authService.getBooking();
      this.user = this.authService.getUser();
      console.log("BOOKING", this.booking, "USER", this.user);
    } else {
      // console.log("CLEANING STORAGE");
      // Storage.clear();
    }
    console.log("URLPARAMS", this.urlParams);
    // console.log("SLUG", this.urlParams["app_slug"]);
    if (this.urlParams !== undefined) {
      if (!!this.urlParams["appSlug"]) {
        // console.log("setting api key", this.urlParams["appSlug"]);
        this.cache.clearAll();
        this.analyticsService.setProperty("appSlug", this.urlParams["appSlug"]);

        this.api
          .get(
            "applications/" + this.urlParams["appSlug"] + "/apiKeys",
            {},
            {
              params: new HttpParams(),
              headers: {
                "X-Authorization": "869aa063611673e941e83fb736757ead33b74f55"
              }
            },
            false,
            true
          )
          .subscribe(async (keys) => {
            // console.log(
            //   "setting key from slug param",
            //   keys.data[0],
            //   this.api.getApiKey()
            // );

            if (keys.data[0] !== this.api.getApiKey()) {
              await this.uiSetupService.clearUISettings();
              await this.langService.resetLangs();
              await this.langService.setLang("en");
              await this.UISetupService.resetApplication();
              // console.log("setting key from slug param", keys.data[0]);
              await this.api.setApiKey(keys.data[0]);
              await this.uiSetupService
                .getApplication()
                .toPromise()
                .then((htApp) => {
                  // console.log(htApp);
                  this.langService.setAvailLangs(htApp.langs);
                  this.uiSetupService
                    .getStoredApplication()
                    .then(async (storedApp) => {
                      if (storedApp.value) {
                        if (JSON.parse(storedApp.value)["id"] !== htApp.id) {
                          await this.authService.logout();
                          await this.uiSetupService.setApplication(htApp);
                          // await this.uiSetupService.chooseAppWeb(htApp, path);
                        }
                      } else {
                        await this.authService.logout();
                        await this.uiSetupService.setApplication(htApp);
                        // await this.uiSetupService.chooseAppWeb(htApp, path);
                      }
                    });
                  // this..setAvailLangs(htApp.langs);
                  this.applogo = htApp.icon;
                  this.wideLogo = htApp.tags.indexOf("WIDELOGO") !== -1;
                  this.title.setTitle(htApp.name);
                  if (this.favIcon && htApp.favicon) {
                    this.favIcon.href = htApp.favicon;
                  }
                  this.toggleDarkModeHandler(htApp.theme === "dark");
                });
            }
            if (!!this.urlParams["hotelId"]) {
              console.log(
                "hotel Ids are the same?",
                this.authService.getHotelId(),
                this.urlParams["hotelId"]
              );

              if (this.authService.getHotelId() !== this.urlParams["hotelId"]) {
                // await this.uiSetupService.clearUISettings();
                await this.authService.logout();
              }
              await this.authService.setHotelId(this.urlParams["hotelId"]);
              this.analyticsService.setProperty(
                "hotelId",
                this.urlParams["hotelId"]
              );
              await this.loadUI();
            } else {
              await this.authService.logout();
            }
            if (
              !!this.urlParams["redirectType"] &&
              !!this.urlParams["redirectId"]
            ) {
              this.checkRedirect();
            } else if (this.urlParams["code"]) {
              this.authService.logout();

              this.router.navigateByUrl("/login", {
                state: { code: this.urlParams["code"] }
              });
            } else {
              this.router.navigateByUrl("/introduction");
            }
          });
      }
    } else {
      if (path === "") {
        this.router.navigateByUrl("/introduction");
      }
    }
  }

  initializeApp() {
    // window.skipLocalNotificationReady = true
    // window.addEventListener("beforeinstallprompt", (e) => {
    //   e.preventDefault();
    // });

    this.platform.ready().then(() => {
      // console.log("this platform", this.platform.platforms());
      // && !this.platform.is("desktop")
      if (
        !this.platform.is("mobileweb") &&
        !document.URL.includes("https://guestapp.hoteltoolbox.gr") &&
        !this.platform.is("desktop")
      ) {
        StatusBar.setOverlaysWebView({ overlay: false });
        StatusBar.hide();
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.setBackgroundColor({ color: "#ffffff" });
        this.pushService.init();
      }

      this.uiSetupService.getStoredApplication().then((htapp) => {
        if (htapp) {
          const application = JSON.parse(htapp.value);
          if (application) {
            this.applogo = application["icon"];
            this.wideLogo = application["tags"].indexOf("WIDELOGO") !== -1;
            this.title.setTitle(application["name"]);
            if (this.favIcon && application["favicon"]) {
              this.favIcon.href = application["favicon"];
            }
            this.toggleDarkModeHandler(application["theme"] === "dark");
          }
        }
      });
      SplashScreen.hide();
      // this.initHotel();

      registerLocaleData(localeDe, "de", localeDeExtra);
      registerLocaleData(localeEl, "el", localeElExtra);

      this.initAnimation();
      this.playAnimation();
      setTimeout(() => {
        this.playEndAnimation();
      }, 1000);

      this.handleLocalNotifications();
      // this.initAnimation();
      App.addListener("appUrlOpen", (data: any) => {
        this.zone.run(async () => {
          const hasParams = /\?(.+?\=.+){1}/;
          let params;

          if (hasParams.test(data.url)) {
            params = {};
            data.url
              .split("?")[1]
              .split("&")
              .forEach((both) => {
                let e = both.split("=");
                params[e[0]] = e[1];
              });
          }

          this.urlParams = params;
          this.urlParams = { appSlug: "HTP" };
          // console.log("DEEPLINK PARAMS", this.urlParams);
          if (this.urlParams !== undefined) {
            if (!!this.urlParams["appSlug"]) {
              await this.uiSetupService.clearUISettings();
              await this.cache.clearAll();
              await this.langService.resetLangs();
              await this.uiSetupService.resetApplication();
              this.analyticsService.setProperty(
                "appSlug",
                this.urlParams["appSlug"]
              );

              // console.log("setting api key", this.urlParams["appSlug"]);
              this.api
                .get(
                  "applications/" + this.urlParams["appSlug"] + "/apiKeys",
                  {},
                  {
                    params: new HttpParams(),
                    headers: {
                      "X-Authorization":
                        "869aa063611673e941e83fb736757ead33b74f55"
                    }
                  },
                  false,
                  true
                )
                .subscribe(async (keys) => {
                  // console.log("setting key from slug param", keys.data[0]);
                  await this.api.setApiKey(keys.data[0]);
                  await this.uiSetupService
                    .getApplication()
                    .toPromise()
                    .then((htApp) => {
                      // console.log(htApp);
                      this.langService.setAvailLangs(htApp.langs);
                      this.uiSetupService.setApplication(htApp);
                      this.applogo = htApp.icon;
                      this.wideLogo = htApp.tags.indexOf("WIDELOGO") !== -1;
                      this.title.setTitle(htApp.name);
                      if (this.favIcon && htApp.favicon) {
                        this.favIcon.href = htApp.favicon;
                      }
                      this.toggleDarkModeHandler(htApp.theme === "dark");
                    });
                  if (this.urlParams["hotelId"]) {
                    if (!!this.urlParams["hotelId"]) {
                      if (this.hotel !== this.urlParams["hotelId"]) {
                        await this.uiSetupService.clearUISettings();
                        this.authService.logout();
                      }
                    }
                    await this.authService.setHotelId(
                      this.urlParams["hotelId"]
                    );
                    this.analyticsService.setProperty(
                      "hotelId",
                      this.urlParams["hotelId"]
                    );
                    await this.loadUI();
                  }
                  if (
                    !!this.urlParams["redirectType"] &&
                    !!this.urlParams["redirectId"]
                  ) {
                    this.checkRedirect();
                  } else if (this.urlParams["code"]) {
                    this.authService.logout();

                    this.router.navigateByUrl("/login", {
                      state: { code: this.urlParams["code"] }
                    });
                  } else {
                    this.router.navigateByUrl("/introduction");
                  }
                });
            }
          }
          // If no match, do nothing - let regular routing
          // logic take over
        });
      });
    });
  }

  initAnimation() {
    // console.log(document.getElementById("b1"));
    let animationObjects = [];
    for (let i = 1; i <= 7; i++) {
      animationObjects.push({
        id: "b" + i,
        element: document.getElementById("b" + i),
        animation: this.animationCtrl
          .create()
          .addElement(document.getElementById("b" + i))
          .duration(3000)
          .easing("ease-in")
          .iterations(Infinity)
          .direction("normal")
          .delay(i * 50)
          .keyframes([
            {
              offset: 0,
              transform: "scale(1) rotate(0)",
              opacity: 1
            },
            {
              offset: 0.5,
              transform:
                "scale(0.99) rotate(" + (Math.random() * 6 - 3) + "deg) ",
              opacity: 0.7
            },
            {
              offset: 1,
              transform: "scale(1) rotate(0) ",
              opacity: 1
            }
          ]),
        endAnimation: this.animationCtrl
          .create()
          .addElement(document.getElementById("b" + i))
          .duration(2000)
          .easing("ease-out")
          .iterations(1)
          .delay(i * 80)
          .keyframes([
            { offset: 0, transform: "scale(1)", opacity: 1 },
            // { offset: 0.5, transform: "scale(3)", opacity: 1 },
            { offset: 1, transform: "scale(5)", opacity: 0 }
          ])
      });
    }
    this.logoAnimation = this.animationCtrl
      .create()
      .addElement(document.getElementById("app-hotel-logo"))
      .duration(600)
      .iterations(1)
      .delay(2000)
      .keyframes([
        {
          offset: 0,
          transform: "translateY(0)",
          opacity: "1"
        },
        {
          offset: 1,
          transform: "translateY(" + -window.innerHeight / 3 + "px)",
          opacity: "0"
        }
      ]);
    this.splashAnimation = this.animationCtrl
      .create()
      .addElement(document.getElementsByClassName("scene")[0])
      .duration(1000)
      // .delay(1000)
      .iterations(1)
      .keyframes([
        { offset: 0, opacity: "1" },
        { offset: 1, opacity: "0" }
      ]);

    this.waveAnimation = this.animationCtrl.create();
    this.endAnimation = this.animationCtrl.create();
    animationObjects.forEach((element) => {
      this.waveAnimation.addAnimation(element.animation);
      this.endAnimation.addAnimation(element.endAnimation);
    });
    this.endAnimation.addAnimation(this.logoAnimation);
  }

  async presentAlert(title, subtitle, msg) {
    const alert = await this.alertCtrl.create({
      cssClass: "my-custom-class",
      header: title,
      subHeader: subtitle || null,
      message: msg,
      buttons: ["OK"]
    });

    await alert.present();
  }
  async initTranslate() {
    // Set the default language for translation strings, and the current language.
    this.languageSub = this.translate.onLangChange.subscribe((res) => {
      setTimeout(() => {
        //checking if translations for given lang exist
        if (Object.keys(res.translations).length === 0)
          this.translate.getTranslation(res.lang).subscribe((langres) => {
            this.translate.setTranslation(res.lang, langres);
          });
      }, 100);
    });
    await this.langService.getLang().then(
      (lang) => {
        // console.log("LANG IS", lang);
        if (lang.value) {
          this.translate.setDefaultLang(lang.value);
          this.translate.use(lang.value);
          moment.locale(lang.value);
          this.langService.getAvailLangs().then((languages) => {
            this.translate.addLangs(languages);
            console.log("ADDED LANGUAGES", languages);
          });
        } else {
          this.langService.getAvailLangs().then((languages) => {
            this.translate.addLangs(languages);
            if (
              !this.platform.is("mobileweb") &&
              !document.URL.includes("https://guestapp.hoteltoolbox.gr") &&
              !this.platform.is("desktop")
            ) {
              Device.getLanguageCode().then(
                (devLang) => {
                  let lang =
                    languages.indexOf(devLang.value) !== -1
                      ? devLang.value
                      : languages[0];
                  this.translate.setDefaultLang(lang);
                  this.translate.use(lang);
                  moment.locale(lang);
                  this.langService.setLang(lang);
                },
                (err) => {
                  moment.locale("en");
                }
              );
            } else {
              let lang =
                languages.indexOf(this.translate.getBrowserLang()) !== -1
                  ? this.translate.getBrowserLang()
                  : languages[0];
              this.translate.setDefaultLang(lang);
              this.translate.use(lang);
              moment.locale(lang);
              this.langService.setLang(lang);
            }
          });
        }
        // console.log(lang, "lang");
      },
      (err) => {
        // console.log(err, "error");
        Device.getLanguageCode().then(
          (devLang) => {
            let lang =
              environment.languages.indexOf(devLang.value) !== -1
                ? devLang.value
                : environment.languages[0];
            this.translate.setDefaultLang(lang);
            moment.locale(lang);
            this.langService.setLang(lang);
          },
          (err) => {
            moment.locale("en");
          }
        );
      }
    );

    moment.updateLocale("el", {
      calendar: {
        lastDay: "[Χθές]",
        sameDay: "HH:mm",
        nextDay: "[Αύριο] HH:mm",
        lastWeek: "dd/MM",
        nextWeek: "dd/MM",
        sameElse: "L"
      }
    });
    moment.updateLocale("de", {
      calendar: {
        lastDay: "[Gestern]",
        sameDay: "HH:mm",
        nextDay: "[Morgen] HH:mm",
        lastWeek: "dd/MM",
        nextWeek: "dd/MM",
        sameElse: "L"
      }
    });
    moment.updateLocale("en", {
      calendar: {
        lastDay: "[Yesterday]",
        sameDay: "HH:mm",
        nextDay: "[Tomorrow] HH:mm",
        lastWeek: "dd/MM",
        nextWeek: "dd/MM",
        sameElse: "L"
      }
    });
  }

  initHotel(hotel) {
    this.hotel = hotel;
    // this.hotelService.getHotel().pipe(take(1)).subscribe((hotel) => {
    // this.hotel = hotel;
    // console.log(hotel);
    // this.pages = hotel.pages;
    // console.log(this.pages);
    // }, error => console.error(error));
  }

  ngOnInit() {
    this.cache.setDefaultTTL(60 * 10);
    this.analyticsService.initFb();

    if (
      document.URL.includes("https://guestapp.hoteltoolbox.gr") ||
      document.URL.includes("https://guest-app.hoteltoolbox.gr") ||
      document.URL.includes("localhost:8200")
    ) {
      Preferences.get({ key: "slugApiKey" }).then(async (key) => {
        if (key) {
          if (key.value) {
            this.api.setApiKey(key.value);
          } else {
            this.api.setApiKey(environment.apikey);
          }
        } else {
          this.api.setApiKey(environment.apikey);
        }
        await this.initTranslate();

        await this.initializeURLParams().then(async () => {
          // console.log("URL PARAMS", this.urlParams);
        });
      });
    } else {
      this.loadUI();
    }

    this.uiSetupService.getThemeUpdateListener().subscribe((darkMode) => {
      setTimeout(() => {
        console.log("this.guestapp", this.guestapp);
        if (this.UISetupService.ui_settings) {
          if (this.UISetupService.ui_settings.cssSettings) {
            const primaryColor = darkMode
              ? this.UISetupService.ui_settings.cssSettings.darkTheme.primary
              : this.UISetupService.ui_settings.cssSettings.lightTheme.primary;
            if (primaryColor) {
              this.guestapp.el.style.setProperty(
                "--ion-color-primary",
                primaryColor
              );
              this.guestapp.el.style.setProperty(
                "--ion-color-primary-rgb",
                this.utilService.hexToRgb(primaryColor)
              );
              this.guestapp.el.style.setProperty(
                "--ion-color-primary-shade",
                this.utilService.mixColor("#000000", primaryColor, 12)
              );
              this.guestapp.el.style.setProperty(
                "--ion-color-primary-tint",
                this.utilService.mixColor("#ffffff", primaryColor, 10)
              );
            }
            // console.log("RGB Color", this.utilService.hexToRgb(primaryColor));
          }
        }
      }, 300);
    });
    this.uiSetupService.getUIUpdateListener().subscribe((uisettings) => {
      if (uisettings) {
        if (uisettings.cssSettings) {
          const darkMode = document.body.classList.contains("dark");
          this.UISetupService.themeChanged(darkMode);
        }
      }
    });
    this.hotelSub = this.authService.hotelChanged.subscribe((res) => {
      // console.log("changed hotel", res);
      this.initHotel(res);
    });
    this.authSub = this.authService.isAuthenticated.subscribe((res) => {
      this.initUser(res);
    });
    this.initTranslate();
    this.initializeApp();
  }

  ngOnDestroy() {
    this.hotelSub.unsubscribe();
    this.authSub.unsubscribe();
    this.languageSub.unsubscribe();
  }

  openDetailsWithState(page: Page) {
    if (!page.description && !page.external_url.url && page.menus.length == 1) {
      let navigationExtras: NavigationExtras = {
        state: {
          order: page.menus[0],
          displayOnly: true
        }
      };
      this.router.navigate(["order/" + page.menus[0]], navigationExtras);
    } else {
      if (page.taglist.HOTELMAP) {
        this.router.navigate(["hotel-map"], {});
      } else {
        let navigationExtras: NavigationExtras = {
          state: {
            page: page
          }
        };
        this.router.navigate(["page-view/" + page.id], navigationExtras);
      }
    }
  }

  initUser(isAuthenticated) {
    if (isAuthenticated) {
      // this.user = this.authService.getUser();
      // console.log(this.authService.getUser(), this.authService.getToken());
    }
  }
  openHotelMap(hotel) {
    let navigationExtras: NavigationExtras = {
      state: {
        hotel: hotel
      }
    };
    this.router.navigate(["hotel-map"], navigationExtras);
  }

  async reserve(page) {
    const modal = await this.modalController.create({
      component: ReservationPage,
      cssClass: "reservation-form",
      componentProps: {
        resId: page.id,
        type: "pages",
        reservationTitle: page.name,
        reservationForm: page.reservationForm,
        entitlement_badge_label: page.entitlement_badge_label,
        entitlement_badge_description: page.entitlement_badge_description
      }
    });
    return await modal.present();
  }

  async playAnimation() {
    // console.log("init animation");
    // this.waveAnimation.play();
    // await this.animationObjects.play();
  }

  async playEndAnimation() {
    // console.log("ending animation");
    // this.waveAnimation.stop();
    await this.endAnimation.play();

    // await this.logoAnimation.play();
    await this.splashAnimation.play();
    this.showSplash = false;
  }

  startRedirection(option, type, displayOnly: boolean = false) {
    let navigationExtras: NavigationExtras;
    switch (type) {
      case "reservations":
        navigationExtras = {
          state: {
            resId: option.id,
            type: "pages",
            reservationTitle: option.name,
            reservationForm: option.reservationForm,
            reservation: option,
            entitlement_badge_label: option.entitlement_badge_label,
            entitlement_badge_description: option.entitlement_badge_description
          }
        };
        if (this.checkUnregistered(option, type)) {
          break;
        }
        this.router.navigate(["reservation/" + option.id], navigationExtras);
        break;
      case "concierge":
        navigationExtras = {
          state: {
            service: option
          }
        };
        if (this.checkUnregistered(option, type)) {
          break;
        }
        this.router.navigate(["service/" + option.id], navigationExtras);
        break;
      case "orderForm":
        navigationExtras = {
          state: {
            order: option,
            orderSpot: this.urlParams["f_orderSpot"]
              ? this.urlParams["f_orderSpot"]
              : "",
            displayOnly: displayOnly
          }
        };
        if (this.checkUnregistered(option, type)) {
          break;
        }
        this.router.navigate(["order/" + option.id], navigationExtras);
        break;
      case "questionnaire":
        navigationExtras = {
          state: {
            questionnaire: option
          }
        };
        if (this.checkUnregistered(option, type)) {
          break;
        }
        this.router.navigate(["questionnaire/" + option.id], navigationExtras);
        break;
      case "promotions":
        break;
    }
  }

  openMenu(menuId) {
    const navigationExtras = {
      state: {
        order: { id: menuId },
        displayOnly: true
      }
    };
    this.analyticsService.logEvent(EVENTTYPES.OPEN_MENU, {
      id: menuId.id,
      hotelId: this.urlParams["hotelId"],
      origin: EVENTORIGIN.URL_LINK
    });
    this.router.navigate(["order/" + menuId], navigationExtras);
  }

  openPage(pageId, hotel) {
    const page = this.findHotelPage(pageId, hotel.pages);
    console.log(page, pageId, hotel.pages);
    // this.analyticsService.logEvent(EVENTTYPES.OPEN_PAGE, {
    //   id: page.id,
    //   name: page.name,
    //   hotelId: hotel.id,
    //   origin: EVENTORIGIN.URL_LINK
    // });
    if (page) {
      const navigationExtras: NavigationExtras = {
        state: {
          page: page
        }
      };
      // console.log(!!page.description, !page.sections, page);
      this.router.navigate(["page-view/" + page.id], navigationExtras);
    } else {
      // console.log("no such page found", pageId);
    }
  }

  findHotelPage(pageId, pages) {
    if (pages) {
      for (let index = 0; index < pages.length; index++) {
        const element = pages[index];
        pages;

        if (element.id === pageId) {
          return element;
        }

        const subpage = this.findHotelPage(pageId, element.children);
        if (subpage) {
          return subpage;
        }
      }
    } else {
      return null;
    }
  }

  checkUnregistered(option, type) {
    if (type == "reservations" || type == "orderForm") {
      console.log("GOT INTO RESERVATIONS");
      if (!!this.user) {
        const isPastCheckout: Boolean = moment().isSameOrAfter(
          moment(this.booking.checkout),
          "day"
        );
        // console.log("isPastCheckout", isPastCheckout);
        if (isPastCheckout) {
          this.loginMessage(
            this.translate.instant("GENERAL.BOOKING_EXPIRED_TITLE"),
            this.translate.instant("GENERAL.BOOKING_EXPIRED")
          );
          return true;
        }
      } else {
        this.loginMessage(
          this.translate.instant("SERVICES.NEED_AUTH"),
          this.translate.instant("SERVICES.NEED_AUTH_DESC")
        );
        return true;
      }
    } else if (type === "concierge") {
      console.log("GOT INTO concierge");

      if (!option.unregistered_guest_submit) {
        // console.log(this.user);
        if (!!this.user) {
          const isPastCheckout: Boolean = moment().isSameOrAfter(
            moment(this.booking.checkout),
            "day"
          );
          // console.log("isPastCheckout", isPastCheckout);
          if (isPastCheckout) {
            this.loginMessage(
              this.translate.instant("GENERAL.BOOKING_EXPIRED_TITLE"),
              this.translate.instant("GENERAL.BOOKING_EXPIRED")
            );
            return true;
          }
        } else {
          this.loginMessage(
            this.translate.instant("SERVICES.NEED_AUTH"),
            this.translate.instant("SERVICES.NEED_AUTH_DESC")
          );
          return true;
        }
      }
    } else if (type === "questionnaire") {
      console.log("checking type", option);
      if (!option.unregistered_submit) {
        console.log(this.user, option.unregistered_submit, option);
        if (!!this.user) {
          const isPastCheckout: Boolean = moment().isSameOrAfter(
            moment(this.booking.checkout),
            "day"
          );
          // console.log("isPastCheckout", isPastCheckout);
          if (isPastCheckout) {
            this.loginMessage(
              this.translate.instant("GENERAL.BOOKING_EXPIRED_TITLE"),
              this.translate.instant("GENERAL.BOOKING_EXPIRED")
            );
            return true;
          }
        } else {
          console.log(this.user, option.unregistered_submit, option);

          this.loginMessage(
            this.translate.instant("SERVICES.NEED_AUTH"),
            this.translate.instant("SERVICES.NEED_AUTH_DESC")
          );
          return true;
        }
      }
    }

    return false;
  }

  async loginMessage(title, message) {
    const alert = await this.alertCtrl.create({
      cssClass: "resubmit-alert",
      header: title,
      message: message,
      backdropDismiss: false,
      inputs: [
        {
          name: "login",
          type: "text",
          placeholder: this.translate.instant("LOGIN.WRITE_CODE")
        }
      ],
      buttons: [
        {
          text: this.translate.instant("FORMS.CANCEL"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            // console.log(" Cancel: blah");
          }
        },
        {
          text: this.translate.instant("LOGIN.LOGIN"),
          handler: (alertData) => {
            if (alertData["login"]) {
              this.login(alertData["login"]).then((loggedin) => {
                if (loggedin) {
                  setTimeout(async () => {
                    alert.dismiss();
                    // this.initializeURLParams();
                    this.booking = await this.authService.getBooking();
                    this.user = this.authService.getUser();
                    this.checkRedirect();
                  }, 1500);
                }
              });
            } else {
              return false;
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async login(code) {
    // const loading = await this.loadingController.create();
    // await loading.present();
    // && !this.platform.is("desktop")
    let state =
      !this.platform.is("mobileweb") &&
      !document.URL.includes("https://guestapp.hoteltoolbox.gr") &&
      !this.platform.is("desktop")
        ? await this.pushService.getDeviceState()
        : null;
    // console.log("ONE SIGNAL ID", state);
    let data = {
      code: code
    };
    if (state) {
      data["onesignal_user_id"] = state["userId"];
    }

    return this.authService.login(data).subscribe(
      (res) => {
        // await loading.dismiss();
        // await this.router.navigateByUrl("/login-confirmation", {
        //   replaceUrl: true,
        // });
        return true;
      },
      async (res) => {
        // await loading.dismiss();
        // console.log(res);
        let message = "";
        if (res.error.error) {
          Object.keys(res.error.error).forEach((key) => {
            message += res.error.error[key] + "\n";
          });
        }
        const alert = await this.toastCtrl.create({
          // header: this.translate.instant("LOGIN.FAILED"),
          message: message,
          position: "middle",
          duration: 3000
          // buttons: ["OK"],
        });
        await alert.present();
        return false;
      }
    );
  }

  handleLocalNotifications() {
    // this.localnotifications.fireQueuedEvents().then((res) => {
    // console.log("fired notifications", res);
    // });

    LocalNotifications.addListener(
      "localNotificationReceived",
      async (notification) => {
        // console.log("notification", notification);
        // const data = JSON.parse(notification.data);
        //  {"schedule":{"at":"2022-10-10T13:14:07Z"},"body":"Your activity starts in 30 min!!!","sound":"","actionTypeId":"","id":491,"title":"Scuba Diving","extra":{"id":48,"type":"activity-alert"},"attachments":[]}
        // await this.presentAlert(notification.title, null, notification.body);
        await this.presentPopover(
          notification.extra.id,
          notification.title,
          notification.body,
          notification.extra.space,
          notification.extra.category,
          notification.extra.image
        );
      }
    );

    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      async (notification) => {
        // console.log("action performed notification", notification);
        // const data = JSON.parse(notification.data);
        await this.presentPopover(
          notification.notification.extra.id,
          notification.notification.title,
          notification.notification.body,
          notification.notification.extra.space,
          notification.notification.extra.category,
          notification.notification.extra.image
        );

        // this.presentAlert(
        //   notification.notification.title,
        //   null,
        //   notification.notification.body
        // );
      }
    );

    // this.localnotifications.on("click").subscribe(async (notification) => {
    // console.log("notification", notification);
    //   // const data = JSON.parse(notification.data);
    //   await this.presentAlert("got a notification");
    // });
  }

  async presentPopover(activityId, title, message, space, category, image) {
    const popover = await this.popoverController.create({
      component: ActivityAlertPage,
      cssClass: "activity-alert-popover",
      event: null,
      translucent: true,
      componentProps: {
        activityId: activityId,
        title: title,
        message: message,
        space: space,
        category: category,
        image: image
      }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    // console.log("onDidDismiss resolved with role", role);
  }

  checkRedirect() {
    // console.log("this.urlParams", this.urlParams);

    switch (this.urlParams["redirectType"]) {
      case "reservations":
        this.reservationService
          .getById(this.urlParams["redirectId"])
          .toPromise()
          .then((request) => {
            this.analyticsService.logEvent(EVENTTYPES.OPEN_RESERVATION, {
              id: request.id,
              name: request.name,
              hotelId: this.urlParams["hotelId"],
              origin: EVENTORIGIN.URL_LINK
            });
            this.router
              .navigateByUrl("/tabs/home", {
                replaceUrl: true,
                state: { refreshUI: true }
              })
              .then(() => {
                this.startRedirection(request, "reservations");
              });
          });
        break;
      case "concierge":
        this.servicesService
          .getById(this.urlParams["redirectId"])
          .toPromise()
          .then((request) => {
            this.analyticsService.logEvent(EVENTTYPES.OPEN_SERVICE, {
              id: request.id,
              name: request.name,
              hotelId: this.urlParams["hotelId"],
              origin: EVENTORIGIN.URL_LINK
            });
            this.router
              .navigateByUrl("/tabs/home", {
                replaceUrl: true,
                state: { refreshUI: true }
              })
              .then(() => {
                this.startRedirection(request, "concierge");
              });
          });
        break;
      case "orderForm":
        this.orderService
          .getById(this.urlParams["redirectId"])
          .toPromise()
          .then((request) => {
            this.analyticsService.logEvent(EVENTTYPES.OPEN_ORDER, {
              id: request.id,
              name: request.name,
              hotelId: this.urlParams["hotelId"],
              origin: EVENTORIGIN.URL_LINK
            });
            this.router
              .navigateByUrl("/tabs/home", {
                replaceUrl: true,
                state: { refreshUI: true }
              })
              .then(() => {
                this.startRedirection(request, "orderForm");
              });
          });
        break;
      case "questionnaire":
        this.questionnaireService
          .getById(this.urlParams["redirectId"])
          .toPromise()
          .then((questionnaire) => {
            console.log("QUESTIONNAIRE", questionnaire);
            this.analyticsService.logEvent(EVENTTYPES.OPEN_QUESTIONNAIRE, {
              id: questionnaire.id,
              name: questionnaire.name,
              hotelId: this.urlParams["hotelId"],
              origin: EVENTORIGIN.URL_LINK
            });
            this.router
              .navigateByUrl("/tabs/home", {
                replaceUrl: true,
                state: { refreshUI: true }
              })
              .then(() => {
                this.startRedirection(questionnaire, "questionnaire");
              });
          });
        break;
      case "mailquestionnaire":
        this.questionnaireService
          .getById(this.urlParams["redirectId"])
          .toPromise()
          .then((questionnaire) => {
            this.analyticsService.logEvent(EVENTTYPES.OPEN_QUESTIONNAIRE, {
              id: questionnaire.id,
              name: questionnaire.name,
              hotelId: this.urlParams["hotelId"],
              origin: EVENTORIGIN.URL_LINK
            });
            // questionnaire.qAuthCode = this.urlParams["authCode"];
            // this.authService.logout();
            console.log("Redirect to questionnaire", questionnaire);
            const authTkn = this.urlParams["authCode"]
              ? decodeURI(this.urlParams["authCode"])
              : "";
            this.authService.setToken(authTkn);
            // this.startRedirection(questionnaire, "mailquestionnaire");
            this.router.navigateByUrl("questionnaire/" + questionnaire.id, {
              replaceUrl: true,
              state: { questionnaire: questionnaire, singlepagemode: true }
            });
          });
        break;
      case "menu":
        this.router
          .navigateByUrl("/tabs/home", {
            state: { refreshUI: true }
          })
          .then(() => {
            this.openMenu(this.urlParams["redirectId"]);
          });
        break;
      case "page":
        setTimeout(() => {
          this.router
            .navigateByUrl("/tabs/home", {
              state: { refreshUI: true }
            })
            .then(() => {
              this.openPage(
                this.urlParams["redirectId"],
                this.hotelService.hotel
              );
            });
        }, 1000);
        break;
      case "home":
        this.router.navigateByUrl("/tabs/home", {
          replaceUrl: true,
          state: { refreshUI: true }
        });
        break;
      case "hotel":
        this.router.navigateByUrl("/tabs/home", {
          replaceUrl: true,
          state: { tab: "hotel" }
        });
        break;
      case "service-hub":
        this.router.navigateByUrl("/tabs/home", {
          replaceUrl: true,
          state: { tab: "service-hub" }
        });
        break;
      case "activities-list":
        this.router.navigateByUrl("/tabs/home", {
          replaceUrl: true,
          state: { tab: "activities-list" }
        });
        break;
      case "activities":
        this.router.navigateByUrl("/tabs/home", {
          replaceUrl: true,
          state: { tab: "activities" }
        });
        break;
      case "help":
        this.router.navigateByUrl("/tabs/home", {
          replaceUrl: true,
          state: { tab: "help" }
        });
        break;
    }
  }
}
