import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { environment } from "src/environments/environment";

@Pipe({ name: "dateFormat" })
export class MomentPipe implements PipeTransform {
  transform(value: Date | moment.Moment, dateFormat: string): any {
    return moment(value).tz(environment.timezone).format(dateFormat);
  }
}
