import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { AuthenticationService } from "../authentication/authentication.service";
import { Menu } from "../../models/menu";

@Injectable({
  providedIn: "root"
})
export class MenuService {
  constructor(
    private api: ApiService,
    private authService: AuthenticationService
  ) {}

  getAll(): Observable<Menu[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/menus",
        {},
        null,
        false
      )
      .pipe(
        map((res) => {
          // console.log(res);
          return res["data"];
        })
      );
  }

  getMenu(id): Observable<Menu> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/menus/" + id,
        {},
        null,
        false
      )
      .pipe(
        map((res) => {
          // console.log(res);
          return res["data"];
        })
      );
  }

  getMenuById(id): Observable<Menu> {
    const token = this.authService.getToken();
    if (token) {
      return this.api
        .get(
          "hotels/" +
            this.authService.getHotelId() +
            "/menus/" +
            id +
            "/personalized",
          null,
          {
            headers: {
              Authorization: "Bearer " + this.authService.getToken()
            }
          }
        )
        .pipe(
          map((data, key) => {
            let res = data["data"];
            // res.forEach(element => {
            //   element.created_at = element.created_at ? moment(element.created_at, 'YYYY-MM-DD HH:mm') : null;
            // });
            return res;
          })
        );
    } else {
      return this.api
        .get("hotels/" + this.authService.getHotelId() + "/menus/" + id, null)
        .pipe(
          map((data, key) => {
            let res = data["data"];
            // res.forEach(element => {
            //   element.created_at = element.created_at ? moment(element.created_at, 'YYYY-MM-DD HH:mm') : null;
            // });
            return res;
          })
        );
    }
  }
}
