import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

/*
  Generated class for the RatingProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class RatingService {
  constructor(public http: HttpClient) {}

  calculateStars(rating) {
    let starJson = [];

    let icon = "star-outline";

    for (let i = 0; i < 5; i++) {
      if (rating != null && rating != undefined) {
        icon =
          i < Math.floor(rating)
            ? "assets/imgs/menu-star-full.svg"
            : i < rating
            ? "star-half"
            : "assets/imgs/menu-star-empty.svg";
      } else {
        icon = "assets/imgs/menu-star-empty.svg";
      }
      starJson.push({
        id: i,
        icon: icon
      });
    }

    return starJson;
  }
}
