import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-nps-confirmation",
  templateUrl: "./nps-confirmation.component.html",
  styleUrls: ["./nps-confirmation.component.scss"]
})
export class NpsConfirmationComponent implements OnInit {
  @Input() rate: Number;
  @Input() maxstep: Number;
  @Input() comment: string;
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {}

  dismiss(save) {
    this.popoverCtrl.dismiss({ save: save, comment: this.comment });
  }
}
