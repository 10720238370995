import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AutoLoginGuard } from "./ht-client-core/common/guards/auto-login.guard";

const routes: Routes = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  // },

  {
    path: "",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "",
    loadChildren: () =>
      import("./ht-client-core/common/pages/tabs/tabs.module").then(
        (m) => m.TabsPageModule
      )
  },
  {
    path: "page-view/:id",
    loadChildren: () =>
      import(
        "./ht-client-core/pagesModule/pages/page-view/page-view.module"
      ).then((m) => m.PageViewPageModule)
  },
  // {
  //   path: 'businesses-catalogue',
  //   loadChildren: () => import('./ht-client-core/reservationsModule/pages/businesses-catalogue/businesses-catalogue.module').then(m => m.BusinessesCataloguePageModule)
  // },
  // {
  //   path: 'business-details/:id',
  //   loadChildren: () => import('./ht-client-core/reservationsModule/pages/business-details/business-details.module').then(m => m.BusinessDetailsPageModule)
  // },
  {
    path: "reservation/:id",
    loadChildren: () =>
      import(
        "./ht-client-core/reservationsModule/pages/reservation/reservation.module"
      ).then((m) => m.ReservationPageModule)
  },
  {
    path: "login",
    loadChildren: () =>
      import("./ht-client-core/common/pages/login/login.module").then(
        (m) => m.LoginPageModule
      ),
    canLoad: [AutoLoginGuard]
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./ht-client-core/common/pages/settings/settings.module").then(
        (m) => m.SettingsPageModule
      )
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./ht-client-core/common/pages/profile/profile.module").then(
        (m) => m.ProfilePageModule
      )
  },
  {
    path: "service/:id",
    loadChildren: () =>
      import(
        "./ht-client-core/servicesModule/pages/service/service.module"
      ).then((m) => m.ServicePageModule)
  },
  {
    path: "questionnaire/:id",
    loadChildren: () =>
      import(
        "./ht-client-core/questionnairesModule/pages/questionnaire/questionnaire.module"
      ).then((m) => m.QuestionnairePageModule)
  },
  {
    path: "questionnaires-list",
    loadChildren: () =>
      import(
        "./ht-client-core/questionnairesModule/pages/questionnaires-list/questionnaires-list.module"
      ).then((m) => m.QuestionnairesListPageModule)
  },
  {
    path: "introduction",
    loadChildren: () =>
      import(
        "./ht-client-core/common/pages/introduction/introduction.module"
      ).then((m) => m.IntroductionPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: "hotels-list",
    loadChildren: () =>
      import(
        "./ht-client-core/common/pages/hotels-list/hotels-list.module"
      ).then((m) => m.HotelsListPageModule)
  },
  {
    path: "login-confirmation",
    loadChildren: () =>
      import(
        "./ht-client-core/common/pages/login-confirmation/login-confirmation.module"
      ).then((m) => m.LoginConfirmationPageModule)
  },
  {
    path: "hotel",
    loadChildren: () =>
      import("./ht-client-core/common/pages/hotel/hotel.module").then(
        (m) => m.HotelPageModule
      )
  },
  {
    path: "service-hub",
    loadChildren: () =>
      import(
        "./ht-client-core/common/pages/service-hub/service-hub.module"
      ).then((m) => m.ServiceHubPageModule)
  },
  {
    path: "help",
    loadChildren: () =>
      import("./ht-client-core/common/pages/help/help.module").then(
        (m) => m.HelpPageModule
      )
  },
  {
    path: "reservation-history",
    loadChildren: () =>
      import(
        "./ht-client-core/reservationsModule/pages/reservation-history/reservation-history.module"
      ).then((m) => m.ReservationHistoryPageModule)
  },
  {
    path: "service-history",
    loadChildren: () =>
      import(
        "./ht-client-core/servicesModule/pages/service-history/service-history.module"
      ).then((m) => m.ServiceHistoryPageModule)
  },
  {
    path: "service-review/:id",
    loadChildren: () =>
      import(
        "./ht-client-core/servicesModule/pages/service-review/service-review.module"
      ).then((m) => m.ServiceReviewPageModule)
  },
  {
    path: "requests-list/:type",
    loadChildren: () =>
      import(
        "./ht-client-core/requestsModule/pages/requests-list/requests-list.module"
      ).then((m) => m.RequestsListPageModule)
  },
  {
    path: "activities-list",
    loadChildren: () =>
      import(
        "./ht-client-core/activitiesModule/pages/activities-list/activities-list.module"
      ).then((m) => m.ActivitiesListPageModule)
  },
  {
    path: "activities",
    loadChildren: () =>
      import(
        "./ht-client-core/activitiesModule/pages/activities/activities.module"
      ).then((m) => m.ActivitiesPageModule)
  },
  {
    path: "activity-details",
    loadChildren: () =>
      import(
        "./ht-client-core/activitiesModule/pages/activity-details/activity-details.module"
      ).then((m) => m.ActivityDetailsPageModule)
  },
  {
    path: "hotel-map",
    loadChildren: () =>
      import("./ht-client-core/common/pages/hotel-map/hotel-map.module").then(
        (m) => m.HotelMapPageModule
      )
  },
  {
    path: "poi-map",
    loadChildren: () =>
      import("./ht-client-core/poisModule/pages/poi-map/poi-map.module").then(
        (m) => m.PoiMapPageModule
      )
  },
  {
    path: "order/:id",
    loadChildren: () =>
      import("./ht-client-core/ordersModule/pages/order/order.module").then(
        (m) => m.OrderPageModule
      )
  },
  {
    path: "order-modal",
    loadChildren: () =>
      import(
        "./ht-client-core/ordersModule/pages/order-modal/order-modal.module"
      ).then((m) => m.OrderModalPageModule)
  },
  {
    path: "order-overview",
    loadChildren: () =>
      import(
        "./ht-client-core/ordersModule/pages/order-overview/order-overview.module"
      ).then((m) => m.OrderOverviewPageModule)
  },
  {
    path: "request-confirmation",
    loadChildren: () =>
      import(
        "./ht-client-core/requestsModule/pages/request-confirmation/request-confirmation.module"
      ).then((m) => m.RequestConfirmationPageModule)
  },
  {
    path: "order-history",
    loadChildren: () =>
      import(
        "./ht-client-core/ordersModule/pages/order-history/order-history.module"
      ).then((m) => m.OrderHistoryPageModule)
  },
  {
    path: "reservation-overview",
    loadChildren: () =>
      import(
        "./ht-client-core/reservationsModule/pages/reservation-overview/reservation-overview.module"
      ).then((m) => m.ReservationOverviewPageModule)
  },
  {
    path: "questionnaire-history",
    loadChildren: () =>
      import(
        "./ht-client-core/questionnairesModule/pages/questionnaire-history/questionnaire-history.module"
      ).then((m) => m.QuestionnaireHistoryPageModule)
  },
  {
    path: "reservation-details/:id",
    loadChildren: () =>
      import(
        "./ht-client-core/reservationsModule/pages/reservation-details/reservation-details.module"
      ).then((m) => m.ReservationDetailsPageModule)
  },
  {
    path: "feedback/:id",
    loadChildren: () =>
      import(
        "./ht-client-core/questionnairesModule/pages/feedback/feedback.module"
      ).then((m) => m.FeedbackPageModule)
  },
  {
    path: "request-details",
    loadChildren: () =>
      import(
        "./ht-client-core/requestsModule/pages/request-details/request-details.module"
      ).then((m) => m.RequestDetailsPageModule)
  },
  {
    path: "order-review/:id",
    loadChildren: () =>
      import(
        "./ht-client-core/ordersModule/pages/order-review/order-review.module"
      ).then((m) => m.OrderReviewPageModule)
  },
  {
    path: "faq-modal",
    loadChildren: () =>
      import(
        "./ht-client-core/pagesModule/pages/faq-modal/faq-modal.module"
      ).then((m) => m.FaqModalPageModule)
  },
  {
    path: "notification-list",
    loadChildren: () =>
      import(
        "./ht-client-core/notificationsModule/pages/notification-list/notification-list.module"
      ).then((m) => m.NotificationListPageModule)
  },
  {
    path: "notification/:id",
    loadChildren: () =>
      import(
        "./ht-client-core/notificationsModule/pages/notification/notification.module"
      ).then((m) => m.NotificationPageModule)
  },
  {
    path: "setup/:apiKey",
    loadChildren: () =>
      import("./pages/setup/setup.module").then((m) => m.SetupPageModule)
  },
  {
    path: "setup/:apiKey/:code",
    loadChildren: () =>
      import("./pages/setup/setup.module").then((m) => m.SetupPageModule)
  },
  {
    path: "item-extras",
    loadChildren: () =>
      import(
        "./ht-client-core/ordersModule/pages/item-extras/item-extras.module"
      ).then((m) => m.ItemExtrasPageModule)
  },
  {
    path: "poi-map-filter",
    loadChildren: () =>
      import(
        "./ht-client-core/poisModule/pages/poi-map-filter/poi-map-filter.module"
      ).then((m) => m.PoiMapFilterPageModule)
  },
  {
    path: "poi-view/:id",
    loadChildren: () =>
      import("./ht-client-core/poisModule/pages/poi-view/poi-view.module").then(
        (m) => m.PoiViewPageModule
      )
  },
  {
    path: "promotion-dialog",
    loadChildren: () =>
      import(
        "./ht-client-core/common/pages/promotion-dialog/promotion-dialog.module"
      ).then((m) => m.PromotionDialogPageModule)
  },
  {
    path: "activity-alert",
    loadChildren: () =>
      import(
        "./ht-client-core/activitiesModule/pages/activity-alert/activity-alert.module"
      ).then((m) => m.ActivityAlertPageModule)
  },
  {
    path: "preload",
    loadChildren: () =>
      import("./ht-client-core/common/pages/preload/preload.module").then(
        (m) => m.PreloadPageModule
      )
  },
  {
    path: "mail-questionnaire-success",
    loadChildren: () =>
      import(
        "./ht-client-core/questionnairesModule/pages/mail-questionnaire-success/mail-questionnaire-success.module"
      ).then((m) => m.MailQuestionnaireSuccessPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
