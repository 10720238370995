import { Pipe, PipeTransform } from "@angular/core";

/**
 * Generated class for the LocalizeDatePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: "replacegreekaccent"
})
export class GreekReplaceAccentedPipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    var charList = {
      Ά: "Α",
      ά: "α",
      Έ: "Ε",
      έ: "ε",
      Ή: "Η",
      ή: "η",
      Ί: "Ι",
      ί: "ι",
      ΐ: "ϊ",
      Ό: "Ο",
      ό: "ο",
      Ύ: "Υ",
      ύ: "υ",
      ΰ: "ϋ",
      Ώ: "Ω",
      ώ: "ω",
      ς: "Σ"
    };
    if (value) {
      return value.replace(/./g, function (c) {
        return c in charList ? charList[c] : c;
      });
    } else {
      return "";
    }
  }
}
