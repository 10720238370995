import { Injectable } from "@angular/core";
import { ApiService } from "../../common/services/api/api.service";
import { Page } from "../../common/models/page";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "../../common/services/authentication/authentication.service";
@Injectable({
  providedIn: "root"
})
export class HotelPagesService {
  constructor(
    private api: ApiService,
    private authService: AuthenticationService
  ) {}

  getPages(): Observable<Page[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId(),
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus,pages.children.menus.orderForms"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"].pages["data"];
        })
      );
  }
}
