import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import * as moment from "moment";
@Component({
  selector: "app-custom-date-input",
  templateUrl: "./custom-date-input.component.html",
  styleUrls: ["./custom-date-input.component.scss"]
})
export class CustomDateInputComponent extends FieldType {
  monthNames;
  constructor() {
    super();
    this.monthNames = moment.monthsShort();
  }
}
