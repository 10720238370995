import { Component, OnInit } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { FieldArrayType, FormlyFormBuilder } from "@ngx-formly/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-repeat-group",
  templateUrl: "./repeat-group.component.html",
  styleUrls: ["./repeat-group.component.scss"]
})
export class RepeatGroupComponent extends FieldArrayType {
  constructor(
    builder: FormlyFormBuilder,
    private alertCtrl: AlertController,
    private translateService: TranslateService
  ) {
    super(builder);
  }

  async remove(index) {
    let counter = index + 1;
    let alert = await this.alertCtrl.create({
      header: this.translateService.instant("FORMS.DELETE_GROUP"),
      subHeader:
        this.translateService.instant("FORMS.DELETE_GROUP_WARNING") +
        " " +
        this.field.name +
        " " +
        counter,
      buttons: [
        {
          text: this.translateService.instant("FORMS.CANCEL"),
          role: "cancel",
          handler: () => {
            // console.log('Cancel clicked');
          }
        },
        {
          text: this.translateService.instant("OK"),
          handler: () => {
            this.field.fieldGroup.splice(index, 1);
          }
        }
      ]
    });

    await alert.present();
  }
}
