import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../../environments/environment";
import { CacheService } from "ionic-cache";
import { ToastController } from "@ionic/angular";
import { Observable } from "rxjs";
import { Preferences } from "@capacitor/preferences";
@Injectable({
  providedIn: "root"
})
export class ApiService {
  // constructor() { }
  url: string = "";
  key: string = "";
  lang: string;
  constructor(
    public http: HttpClient,
    private translate: TranslateService,
    private cache: CacheService,
    private toastController: ToastController
  ) {
    // this.key = environment.apikey;
    // this.url = environment.apiEndpoint;

    // console.log("checking document url", document.URL);
    if (
      document.URL.includes("https://localhost:8200") ||
      document.URL.includes("http://localhost:8200")
    ) {
      this.url = "api";
    } else {
      this.url = environment.apiEndpoint;
    }
    // console.log("api url", this.url);
  }

  async setApiKey(key) {
    this.key = key;
    await Preferences.set({ key: "slugApiKey", value: key });
  }
  getApiKey() {
    return this.key;
  }
  getCurrentLanguage() {
    // console.log("getting current lang", this.translate.currentLang);
    return this.translate.currentLang || "en";
  }

  get(
    endpoint: string,
    params?: any,
    reqOpts?: any,
    cacheFlag: boolean = false,
    ignoreLang: boolean = false
  ) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: {
          "X-Authorization": this.key
          // "Content-Type": 'application/x-www-form-urlencoded',
        }
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    // if (this.cache.isOnline()) {
    //   this.cache.clearGroup(
    //     this.url +
    //       "/" +
    //       (!ignoreLang ? this.getCurrentLanguage() + "/" : "") +
    //       endpoint
    //   );
    // }

    let request = this.http.get(
      this.url +
        "/" +
        (!ignoreLang ? this.getCurrentLanguage() + "/" : "") +
        endpoint,
      reqOpts
    );

    if (cacheFlag) {
      return this.cache.loadFromObservable(
        this.url +
          "/" +
          (!ignoreLang ? this.getCurrentLanguage() + "/" : "") +
          endpoint,
        request
      );
    }
    return request;
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: {
          "X-Authorization": this.key,
          "Content-Type": "application/json",
          responseType: Response.prototype.json,
          withCredentials: false
        }
      };
    }

    if (reqOpts.params) {
      let prms = new HttpParams();
      for (let k in body) {
        prms = reqOpts.params.append(k, reqOpts.params[k]);
      }
    }
    if (reqOpts.headers) {
      reqOpts.headers["X-Authorization"] = this.key;
      reqOpts.headers["Content-Type"] = "application/json";
      // commented those 2 because editor save did not work otherwise
      // reqOpts.headers["responseType"] = Response.prototype.json;
      // reqOpts.headers["withCredentials"] = false;
    } else {
      reqOpts.headers = {
        "X-Authorization": this.key,
        "Content-Type": "application/json",
        responseType: Response.prototype.json,
        withCredentials: false
      };
    }
    if (!!environment["previewApp"]) {
      return new Observable((sub) => {
        this.toastController.create({
          message: "Cannot submit in app preview"
        });
        sub.next(true);
        sub.complete();
      });
    } else {
      return this.http.post(
        this.url + "/" + this.getCurrentLanguage() + "/" + endpoint,
        body,
        reqOpts
      );
    }
  }

  put(endpoint: string, body: any, reqOpts?: any, ignoreLang: boolean = false) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    const lang = ignoreLang ? "" : "/" + this.getCurrentLanguage();
    return this.http.put(this.url + lang + "/" + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    return this.http.delete(
      this.url + "/" + this.getCurrentLanguage() + "/" + endpoint,
      reqOpts
    );
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: {
          "X-Authorization": this.key,
          "Content-Type": "application/json",
          responseType: Response.prototype.json,
          withCredentials: false
        }
      };
    }

    if (reqOpts.params) {
      let prms = new HttpParams();
      for (let k in body) {
        prms = reqOpts.params.append(k, reqOpts.params[k]);
      }
    }
    if (reqOpts.headers) {
      reqOpts.headers["X-Authorization"] = this.key;
      reqOpts.headers["Content-Type"] = "application/json";
      reqOpts.headers["responseType"] = Response.prototype.json;
      reqOpts.headers["withCredentials"] = false;
    } else {
      reqOpts.headers = {
        "X-Authorization": this.key,
        "Content-Type": "application/json",
        responseType: Response.prototype.json,
        withCredentials: false
      };
    }

    return this.http.patch(
      this.url + "/" + this.getCurrentLanguage() + "/" + endpoint,
      body,
      reqOpts
    );
  }
}
