import { Component, Input, OnInit } from "@angular/core";
import { AnimationController } from "@ionic/angular";

@Component({
  selector: "app-request-confirmation",
  templateUrl: "./request-confirmation.page.html",
  styleUrls: ["./request-confirmation.page.scss"]
})
export class RequestConfirmationPage implements OnInit {
  successAnimation;
  personAnimation;
  personHeadAnimation;
  @Input() type: string;

  constructor(private animationCtrl: AnimationController) {}

  ngOnInit() {
    this.successAnimation = this.animationCtrl
      .create()
      .addElement(document.getElementsByClassName("check-icon")[0])
      .duration(800)
      .iterations(1)
      .easing("ease-in")
      .delay(300)
      .afterStyles({
        opacity: 1
      })
      .keyframes([
        {
          offset: 0,
          transform: "translate(0,0)",
          opacity: 0
        },
        {
          offset: 0.25,
          transform: "translate(0, -30px)",
          opacity: 1
        },
        {
          offset: 0.5,
          transform: "translate(0, 0)",
          opacity: 1
        },
        {
          offset: 0.75,
          transform: "translate(0, -30px)",
          opacity: 1
        },
        {
          offset: 1,
          transform: "translate(0,0)",
          opacity: 1
        }
      ]);

    // person-icon
    this.personAnimation = this.animationCtrl
      .create()
      .addElement(document.getElementsByClassName("person-icon")[0])
      .duration(600)
      .iterations(1)
      .easing("ease-in")
      .delay(300)
      .keyframes([
        {
          offset: 0,
          transform: "translate(500px,0)",
          opacity: 0.3
        },
        {
          offset: 1,
          transform: "translate(0,0)",
          opacity: 1
        }
      ]);
    this.personHeadAnimation = this.animationCtrl
      .create()
      .addElement(document.getElementById("person-face"))
      .duration(400)
      .iterations(1)
      .beforeStyles({
        "transform-origin": "center"
      })
      .afterStyles({
        "transform-origin": "center"
      })
      .easing("ease-in")
      .keyframes([
        {
          offset: 0,
          transform: "rotate(0)"
        },
        {
          offset: 1,
          transform: "rotate(10deg)"
        }
      ]);
    this.playAnimation();
  }

  async playAnimation() {
    // console.log("init animation");
    await this.personAnimation.play();
    this.personHeadAnimation.play();
    await this.successAnimation.play();
  }

  async playEndAnimation() {
    // console.log("ending animation");
    await this.successAnimation.stop();
    this.personHeadAnimation.stop();
    await this.personAnimation.stop();
  }
}
