import { Pipe, PipeTransform } from "@angular/core";
import { Page } from "../../models/page";

@Pipe({
  name: "mapSpaces",
})
export class MapSpacesPipe implements PipeTransform {
  mapPages: Page[] = [];
  transform(pages: Page[]): Page[] {
    this.mapPages = [];
    if (pages) {
      this.checkForSpaces(pages);
    }
    return this.mapPages;
  }

  checkForSpaces(pages, parentName = null) {
    pages.forEach((page) => {
      if (page.space) {
        page.parentName = parentName;
        this.mapPages.push(page);
      } else {
        if (page.children) this.checkForSpaces(page.children, page.name);
      }
    });
  }
}
