import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
/*
  Generated class for the PhotoServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class PhotoServiceProvider {
  constructor() {
    // console.log('Hello PhotoServiceProvider Provider');
  }

  // take Photo
  takePhoto(resType: string = "base64") {
    return Camera.getPhoto({
      quality: 50,
      correctOrientation: true,
      resultType:
        resType == "base64" ? CameraResultType.DataUrl : CameraResultType.Uri,
      source: CameraSource.Prompt,
    }).then(
      (imageData) => {
        console.log(imageData);
        return imageData.dataUrl;
      },
      (err) => {
        // Handle error
        return null;
      }
    );
  }
}
