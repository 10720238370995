import { Location } from "@angular/common";
import { Component, Input, NgZone, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IonSlides,
  ModalController,
  PopoverController,
  ToastController
} from "@ionic/angular";
import { FormlyFormOptions } from "@ngx-formly/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { Reservation } from "src/app/ht-client-core/common/models/reservation";
import { RequestConfirmationPage } from "src/app/ht-client-core/requestsModule/pages/request-confirmation/request-confirmation.page";
import { AuthenticationService } from "../../../../ht-client-core/common/services/authentication/authentication.service";
import { FormsProvider } from "../../../../ht-client-core/common/services/forms/forms";
import { ReservationService } from "../../../reservationsModule/services/reservation.service";
import { ReservationOverviewPage } from "../reservation-overview/reservation-overview.page";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-reservation",
  templateUrl: "./reservation.page.html",
  styleUrls: ["./reservation.page.scss"]
})
export class ReservationPage implements OnInit {
  @ViewChild("generalSlides", { read: IonSlides, static: false })
  generalSlides: IonSlides;
  @ViewChild("resDaySlides", { read: IonSlides, static: false })
  resDaySlides: IonSlides;
  @ViewChild("resSlotSlides", { read: IonSlides, static: false })
  slotSlides: IonSlides;

  missingProfile;
  submitErrorMessage;
  events;
  day;
  days;
  selectedDay;
  selectedSlot;
  state = 0;
  scheduleSlidesOpts = {
    slidesPerView: "auto",
    spaceBetween: 16
  };
  generalSlideOpts = {
    slidesPerView: 1,
    centeredSlides: true,
    activeSlide: 0,
    allowTouchMove: false
  };

  showState;
  progress;
  fields;
  form: FormGroup;
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      disabled: true
    }
  };
  selectedValue = 1;
  user;
  showDesc: Boolean = false;
  booking;
  weekStart;
  resId: string;
  type: string;
  reservation: Reservation;
  loading: boolean = false;
  currentWeek = 0;
  authSub: Subscription;
  timezone: string;
  constructor(
    private toastCtrl: ToastController,
    private reservationsService: ReservationService,
    private modalController: ModalController,
    private forms: FormsProvider,
    private authService: AuthenticationService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private popoverController: PopoverController,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.user = this.authService.getUser();
    this.timezone = environment.timezone;
    moment.locale(this.translate.currentLang);
    this.authSub = this.authService.isAuthenticated.subscribe(() => {
      this.user = this.authService.getUser();
      this.authService.getBooking().then((booking) => {
        this.days = [];
        this.booking = booking;
        this.launchSchedule();
      });
    });

    this.route.queryParams.subscribe((params) => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.resId = this.router.getCurrentNavigation().extras.state.resId;
        this.type = this.router.getCurrentNavigation().extras.state.type;
        this.reservation =
          this.router.getCurrentNavigation().extras.state.reservation;
      }
    });
  }

  launchSchedule(addWeek: Number = 0) {
    this.weekStart = moment().tz(this.timezone).clone().startOf("isoWeek");
    let nowD = new Date();
    this.weekStart.add(addWeek, "week");
    let start = this.weekStart.toISOString().split("T")[0]; //nowD.toISOString().split("T")[0];
    let end;
    this.loading = true;
    if (this.booking) {
      end = this.weekStart.add(7, "days").toDate().toISOString().split("T")[0];
    } else {
      end = this.weekStart.add(7, "days").toDate().toISOString().split("T")[0];
    }

    if (this.missingProfile) {
      this.toastCtrl.create({
        message: "test missing profile message",
        duration: 3000
      });
    } else if (this.submitErrorMessage) {
      this.toastCtrl.create({
        message: "test submit error message",
        duration: 3000
      });
    } else {
      this.showState = 3;
      this.progress += 25;
      this.events = [];
      this.days = [];
      if (!!this.resId && !!this.type) {
        this.reservationsService
          .getPersonalizedSlots(this.resId, this.type, start, end)
          .subscribe((res) => {
            for (
              let i = 0;
              moment(start).add(i, "day").isBefore(moment(end));
              i++
            ) {
              let iDate = moment(start).add(i, "day");
              this.days.push({
                date: iDate.format("YYYY-MM-DD"),
                slots: [],
                availability: false
              });

              if (
                res[iDate.format("YYYY-MM-DD")] &&
                iDate.isBefore(
                  moment(this.booking.checkout).tz(this.timezone),
                  "day"
                )
              )
                res[iDate.format("YYYY-MM-DD")].forEach((slot) => {
                  if (slot.availability > 0) {
                    this.days[this.days.length - 1].availability = true;
                  }

                  if (
                    moment(slot.start)
                      .tz(this.timezone)
                      .isSameOrAfter(moment().tz(this.timezone), "hour")
                  ) {
                    // console.log("booking", this.booking);
                    let countPersons =
                      this.booking.adultCount + this.booking.childCount;
                    if (this.reservation.count_should_include_infant_count) {
                      countPersons += this.booking.infantCount;
                    }
                    this.days[this.days.length - 1].slots.push({
                      title: slot.start,
                      startsAt: new Date(slot.start),
                      endsAt: new Date(slot.end),
                      availability: slot.availability,
                      disabled: slot.availability < countPersons,
                      entitled: slot.entitled
                    });
                  }
                });
              if (this.days[this.days.length - 1].slots.length === 0) {
                this.days[this.days.length - 1].slots.availability = false;
              }
            }
            this.loading = false;
            setTimeout(() => {
              let index = 0;
              while (index < 7) {
                if (
                  moment(this.days[index].date)
                    .tz(this.timezone)
                    .isSameOrAfter(moment().tz(this.timezone), "day") &&
                  this.days[index].availability
                ) {
                  this.selectedDay = this.days[index];
                  break;
                }
                index++;
              }
            }, 200);
          });
      }
    }
  }

  nextWeek() {
    this.currentWeek += 1;
    this.launchSchedule(this.currentWeek);
  }

  prevWeek() {
    if (this.currentWeek > 0) {
      this.currentWeek -= 1;
      this.launchSchedule(this.currentWeek);
    }
  }

  segmentChanged(ev) {
    if (ev) {
      if (ev.detail) {
        if (ev.detail.value) {
          let index = this.findWithAttr(this.days, ev.detail.value, "date");
          if (index !== -1) {
            this.selectedDay = this.days[index];
            if (this.slotSlides) {
              this.selectSlot(this.selectedDay.slots[0], 0);
            }
          }
        }
      }
    }
  }

  selectSlot(slot, index) {
    this.selectedSlot = slot;
    let countPersons = this.booking.adultCount + this.booking.childCount;
    if (this.reservation.count_should_include_infant_count) {
      countPersons += this.booking.infantCount;
    }
    this.selectedValue = countPersons;
    this.slotSlides.slideTo(index, 400);
  }

  daySlidesDidChange(event, slider) {
    slider.getActiveIndex().then((res) => {
      this.selectedDay = this.days[res];
      if (this.slotSlides) {
        this.slotSlides.slideTo(0, 400);
      }
    });
  }

  dismiss() {
    this.modalController.dismiss({});
  }

  findWithAttr(array, value, attr) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }

  changeSpots(n: number) {
    if (n > 0) {
      if (this.selectedValue < this.selectedSlot.availability) {
        this.selectedValue++;
      }
    } else {
      if (this.selectedValue > 1) {
        this.selectedValue--;
      }
    }
  }

  async reservationOverviewModal() {
    const modal = await this.modalController.create({
      component: ReservationOverviewPage,
      cssClass: this.reservation.reservationForm
        ? "order-overview-modal"
        : "order-overview-modal-mini",
      swipeToClose: true,
      showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        reservationForm: this.reservation.reservationForm,
        type: this.type,
        resId: this.resId,
        selectedSlot: this.selectedSlot,
        selectedValue: this.selectedValue
      }
    });
    modal.onDidDismiss().then((res) => {
      if (res) {
        // console.log(res);
        if (res["data"]) {
          if (res["data"]["success"]) {
            this.presentPopover(null);
            this.location.back();
          }
        }
      }
    });
    if (this.selectedSlot) {
      return await modal.present();
    } else {
      const toast = await this.toastCtrl.create({
        message: this.translate.instant("RESERVATIONS.CHOOSE_SLOT_TO_CONTINUE"),
        duration: 3000
      });
      return await toast.present();
    }
  }
  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: RequestConfirmationPage,
      cssClass: "success-request-popover",
      event: ev,
      translucent: true,
      componentProps: {
        type: "reservation"
      }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }

  countPersons() {
    const countPersons = this.booking.adultCount + this.booking.childCount;
    if (this.reservation.count_should_include_infant_count) {
      return countPersons + this.booking.infantCount;
    }
    return countPersons;
  }
}
