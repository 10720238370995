import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/ht-client-core/common/services/api/api.service";
import { AuthenticationService } from "src/app/ht-client-core/common/services/authentication/authentication.service";
import { OrderHistoryItem } from "src/app/ht-client-core/common/models/order-history-item";
import { OrderForm } from "src/app/ht-client-core/common/models/order-form";
import { OrderSpot } from "src/app/ht-client-core/common/models/order-spot";
import { MetaData } from "src/app/ht-client-core/common/models/meta-data";
@Injectable({
  providedIn: "root"
})
export class OrderService {
  constructor(
    private api: ApiService,
    private authService: AuthenticationService
  ) {}

  getAll(): Observable<OrderForm[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/orderForms",
        {},
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }
  //weekSchedule
  getById(id): Observable<OrderForm> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/orderForms/" + id,
        {},
        null,
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  submit(order_id, inputForm, menuData, order_spot_id?) {
    // let menuData = {};
    // menuForm.categories.forEach((cat) => {
    //   cat.items.forEach((item) => {
    //     if (item.quantity)
    //       menuData[item.id] = {
    //         quantity: item.quantity,
    //         comment: item.comment,
    //       };
    //   });
    // });

    return this.api.post(
      "hotels/" +
        this.authService.getHotelId() +
        "/orderForms/" +
        order_id +
        "/orders",
      {
        form_input: inputForm,
        menu_input: menuData,
        order_spot_id: order_spot_id
      },
      {
        headers: {
          Authorization: "Bearer " + this.authService.getToken()
        }
      }
    );
  }

  getHistory(order_id) {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/orders/" + order_id,
        null,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        }
      )
      .pipe(
        map((data, key) => {
          let res = data["data"];
          // res.forEach(element => {
          //   element.created_at = element.created_at ? moment(element.created_at, 'YYYY-MM-DD HH:mm') : null;
          // });
          return { data: res };
        })
      );
  }

  getAllHistory(page: string = "1"): Observable<OrderHistoryItem[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/orders",
        {
          include:
            "orderForm,orderForm.menu,orderForm.menu.categories,orderForm.menu.categories.items,orderSpot",
          page: page
        },
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        }
      )
      .pipe(
        map((data, key) => {
          data["data"].forEach((element) => {
            // element.created_at = element.created_at
            //   ? moment(element.created_at, "YYYY-MM-DD HH:mm")
            //   : null;
            let totalPrice = 0;
            Object.keys(element.menu_input).forEach((item) => {
              totalPrice +=
                element.menu_input[item].quantity *
                Number(element.menu_input[item].price);
            });
            element.totalPrice = totalPrice;
          });
          return data;
        })
      );
  }

  getAllSpots(page): Observable<{ data: OrderSpot[]; meta: MetaData }> {
    page = page || 1;
    console.log("getAllSpots", page);
    return this.api.get(
      "hotels/" + this.authService.getHotelId() + "/orderSpots?page=" + page,
      {
        // page: page
      },
      null,
      true
    );
  }

  prepareOrderList(orderList) {
    let finalOrder = [];
    orderList.forEach((item) => {
      let finalItem = {
        item: item.id,
        comment: item.comment,
        quantity: item.quantity
      };
      if (!!item.extras) {
        if (item.extras.length > 0) {
          finalItem["extras"] = {};
          let newExtra = {};
          item.extras.forEach((extra) => {
            // option.quantity = option.isChecked ? 1 : 0;
            newExtra[extra.id] = [];
            extra.options.forEach((option) => {
              if (option.isChecked) {
                newExtra[extra.id].push({
                  option: option.id,
                  quantity: 1
                });
              } else {
                newExtra[extra.id].push({
                  option: option.id,
                  quantity: 0
                });
              }
            });
          });
          finalItem["extras"] = newExtra;
        }
      }
      finalOrder.push(finalItem);
    });
    return finalOrder;
  }

  // compileMenuInput(menu_input, menu: Menu){
  //   let orderList = [];
  //   menu_input.forEach(item =>{

  //   });
  //   return orderList
  // }
}
