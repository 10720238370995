import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Preferences } from "@capacitor/preferences";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../../services/language/language.service";
@Component({
  selector: "app-language-popover",
  templateUrl: "./language-popover.component.html",
  styleUrls: ["./language-popover.component.scss"]
})
export class LanguagePopoverComponent implements OnInit {
  currentLanguage: string;
  languages: string[];

  constructor(
    public popoverCtrl: PopoverController,
    private translate: TranslateService,
    private langService: LanguageService
  ) {}

  ngOnInit() {
    this.langService.getLang().then((lang) => {
      this.currentLanguage = lang.value;
    });
    this.langService.getAvailLangs().then((languages) => {
      this.languages = languages;
    });
  }

  setLanguage(language: string) {
    this.translate.use(language);
    this.currentLanguage = language;
    Preferences.set({ key: "lang", value: language });
    this.popoverCtrl.dismiss(this.currentLanguage);
  }
}
