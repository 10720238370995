import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationExtras, Router } from "@angular/router";
import { AlertController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment-timezone";
import { OrderService } from "src/app/ht-client-core/ordersModule/services/order/order.service";
import { ReservationService } from "src/app/ht-client-core/reservationsModule/services/reservation.service";
import { Promotion } from "../../models/promotion";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { environment } from "src/environments/environment";
import {
  AnalyticsService,
  EVENTORIGIN,
  EVENTTYPES
} from "../../services/analytics/analytics.service";
import { HotelService } from "../../services/hotel/hotel.service";

@Component({
  selector: "app-promotion-dialog",
  templateUrl: "./promotion-dialog.page.html",
  styleUrls: ["./promotion-dialog.page.scss"]
})
export class PromotionDialogPage implements OnInit {
  @Input() promotion: Promotion;
  @Input() isAuth: boolean;
  @Input() booking;
  timezone = environment.timezone;
  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private orderService: OrderService,
    private reservationService: ReservationService,
    private translate: TranslateService,
    private alertController: AlertController,
    private popoverCtrl: PopoverController,
    private authService: AuthenticationService,
    private analytics: AnalyticsService,
    private hotelService: HotelService
  ) {}

  ngOnInit() {}

  sanitizeHTML(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  openOfferable() {
    switch (this.promotion.offerable_type) {
      case "activity":
        this.popoverCtrl.dismiss().then((_) => {
          this.analytics.logEvent(EVENTTYPES.OPEN_ACTIVITY, {
            id: this.promotion.offerable.id,
            name: this.promotion.offerable.name,
            hotelId: this.hotelService.hotel ? this.hotelService.hotel.id : "",
            origin: EVENTORIGIN.PROMOTION
          });
          this.router.navigate(["activity-details"], {
            state: {
              activity: this.promotion.offerable
            }
          });
        });
        break;
      case "orderForm":
        this.popoverCtrl.dismiss().then((_) => {
          this.orderService
            .getById(this.promotion.offerable_id)
            .toPromise()
            .then((request) => {
              this.analytics.logEvent(EVENTTYPES.OPEN_ORDER, {
                id: request.id,
                name: request.name,
                hotelId: this.hotelService.hotel
                  ? this.hotelService.hotel.id
                  : "",
                origin: EVENTORIGIN.PROMOTION
              });
              this.startRequest({
                action: request,
                type: "order"
              });
            });
        });
        break;
      case "reservable":
        this.popoverCtrl.dismiss().then((_) => {
          this.reservationService
            .getById(this.promotion.offerable_id)
            .toPromise()
            .then((request) => {
              this.analytics.logEvent(EVENTTYPES.OPEN_RESERVATION, {
                id: request.id,
                name: request.name,
                hotelId: this.hotelService.hotel
                  ? this.hotelService.hotel.id
                  : "",
                origin: EVENTORIGIN.PROMOTION
              });
              this.startRequest({
                action: request,
                type: "reservation"
              });
            });
        });
        break;
      case "page":
        this.popoverCtrl.dismiss().then((_) => {
          let navigationExtras: NavigationExtras = {
            state: {
              page: this.promotion.offerable
            }
          };
          this.analytics.logEvent(EVENTTYPES.OPEN_PAGE, {
            id: this.promotion.offerable.id,
            name: this.promotion.offerable.name,
            hotelId: this.hotelService.hotel ? this.hotelService.hotel.id : "",
            origin: EVENTORIGIN.PROMOTION
          });
          this.router.navigate(
            ["page-view/" + this.promotion.offerable_id],
            navigationExtras
          );
        });

        break;
    }
  }

  startRequest(option) {
    let navigationExtras: NavigationExtras;

    switch (option.type) {
      case "reservation":
        navigationExtras = {
          state: {
            resId: option.action.id,
            type: "pages",
            reservation: option.action
          }
        };
        if (this.checkUnregistered(option, "reservations")) {
          break;
        }
        this.router.navigate(
          ["reservation/" + option.action.id],
          navigationExtras
        );
        break;
      case "service":
        navigationExtras = {
          state: {
            service: option.action
          }
        };
        if (this.checkUnregistered(option, "concierge")) {
          break;
        }
        this.router.navigate(["service/" + option.action.id], navigationExtras);
        break;
      case "order":
        navigationExtras = {
          state: {
            order: option.action
          }
        };
        if (this.checkUnregistered(option, "orders")) {
          break;
        }
        this.router.navigate(["order/" + option.action.id], navigationExtras);
        break;
    }
  }

  checkUnregistered(option, type) {
    if (type == "reservations" || "orders") {
      if (this.isAuth) {
        const isPastCheckout: Boolean = moment()
          .tz(this.timezone)
          .isSameOrAfter(
            moment(this.booking.checkout).tz(this.timezone),
            "day"
          );
        // console.log("isPastCheckout", isPastCheckout);
        if (isPastCheckout) {
          this.loginMessage(
            this.translate.instant("GENERAL.BOOKING_EXPIRED_TITLE"),
            this.translate.instant("GENERAL.BOOKING_EXPIRED")
          );
          return true;
        }
      } else {
        this.loginMessage(
          this.translate.instant("SERVICES.NEED_AUTH"),
          this.translate.instant("SERVICES.NEED_AUTH_DESC")
        );
        return true;
      }
    } else if (type === "concierge") {
      if (!option.unregistered_guest_submit) {
        if (this.isAuth) {
          const isPastCheckout: Boolean = moment()
            .tz(this.timezone)
            .isSameOrAfter(
              moment(this.booking.checkout).tz(this.timezone),
              "day"
            );
          // console.log("isPastCheckout", isPastCheckout);
          if (isPastCheckout) {
            this.loginMessage(
              this.translate.instant("GENERAL.BOOKING_EXPIRED_TITLE"),
              this.translate.instant("GENERAL.BOOKING_EXPIRED")
            );
            return true;
          }
        } else {
          this.loginMessage(
            this.translate.instant("SERVICES.NEED_AUTH"),
            this.translate.instant("SERVICES.NEED_AUTH_DESC")
          );
          return true;
        }
      }
    }
  }

  async loginMessage(title, message) {
    const alert = await this.alertController.create({
      cssClass: "resubmit-alert",
      header: title,
      message: message,
      buttons: [
        {
          text: this.translate.instant("FORMS.CANCEL"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            // console.log("Confirm Cancel: blah");
          }
        },
        {
          text: this.translate.instant("LOGIN.LOGIN"),
          handler: () => {
            this.router.navigate(["login"]);
          }
        }
      ]
    });

    return await alert.present();
  }
}
