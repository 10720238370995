import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment-timezone";
import { AlertController } from "@ionic/angular";
import { NavigationExtras, Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class RequestService {
  timezone = environment.timezone;
  constructor(
    private translateService: TranslateService,
    private alertController: AlertController,
    private router: Router
  ) {}

  checkUnregistered(option, type, user, booking) {
    if (type == "reservations" || "orders") {
      if (!!user) {
        const isPastCheckout: Boolean = moment()
          .tz(this.timezone)
          .isSameOrAfter(moment(booking.checkout).tz(this.timezone), "day");
        // console.log("isPastCheckout", isPastCheckout);
        if (isPastCheckout) {
          this.loginMessage(
            this.translateService.instant("GENERAL.BOOKING_EXPIRED_TITLE"),
            this.translateService.instant("GENERAL.BOOKING_EXPIRED")
          );
          return true;
        }
      } else {
        this.loginMessage(
          this.translateService.instant("SERVICES.NEED_AUTH"),
          this.translateService.instant("SERVICES.NEED_AUTH_DESC")
        );
        return true;
      }
    } else if (type === "concierge") {
      if (!option.unregistered_guest_submit) {
        if (!!user) {
          const isPastCheckout: Boolean = moment()
            .tz(this.timezone)
            .isSameOrAfter(moment(booking.checkout).tz(this.timezone), "day");
          // console.log("isPastCheckout", isPastCheckout);
          if (isPastCheckout) {
            this.loginMessage(
              this.translateService.instant("GENERAL.BOOKING_EXPIRED_TITLE"),
              this.translateService.instant("GENERAL.BOOKING_EXPIRED")
            );
            return true;
          }
        } else {
          this.loginMessage(
            this.translateService.instant("SERVICES.NEED_AUTH"),
            this.translateService.instant("SERVICES.NEED_AUTH_DESC")
          );
          return true;
        }
      }
    }

    return false;
  }

  async loginMessage(title, message) {
    const alert = await this.alertController.create({
      cssClass: "resubmit-alert",
      header: title,
      message: message,
      buttons: [
        {
          text: this.translateService.instant("FORMS.CANCEL"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            // console.log(" Cancel: blah");
          }
        },
        {
          text: this.translateService.instant("LOGIN.LOGIN"),
          handler: () => {
            this.router.navigate(["login"]);
          }
        }
      ]
    });

    return await alert.present();
  }

  startRequest(option, type, user, booking) {
    let navigationExtras: NavigationExtras;
    switch (type) {
      case "reservations":
        navigationExtras = {
          state: {
            resId: option.id,
            type: "pages",
            reservation: option
          }
        };
        if (this.checkUnregistered(option, type, user, booking)) {
          break;
        }
        this.router.navigate(["reservation/" + option.id], navigationExtras);
        break;
      case "concierge":
        navigationExtras = {
          state: {
            service: option
          }
        };
        if (this.checkUnregistered(option, type, user, booking)) {
          break;
        }
        this.router.navigate(["service/" + option.id], navigationExtras);
        break;
      case "orders":
        navigationExtras = {
          state: {
            order: option
          }
        };
        if (this.checkUnregistered(option, type, user, booking)) {
          break;
        }
        this.router.navigate(["order/" + option.id], navigationExtras);
        break;
      case "promotions":
        break;
    }
  }
}
