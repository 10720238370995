import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import * as moment from "moment-timezone";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/ht-client-core/common/services/api/api.service";
import { AuthenticationService } from "src/app/ht-client-core/common/services/authentication/authentication.service";
import { HotelService } from "src/app/ht-client-core/common/services/hotel/hotel.service";
import { OrderService } from "src/app/ht-client-core/ordersModule/services/order/order.service";
import { ReservationService } from "src/app/ht-client-core/reservationsModule/services/reservation.service";
import { ServicesService } from "src/app/ht-client-core/servicesModule/services/services.service";
import { RequestService } from "src/app/ht-client-core/requestsModule/services/request.service";
import { PromotionService } from "src/app/ht-client-core/common/services/promotion/promotion.service";
import { Booking } from "src/app/ht-client-core/common/models/booking";
import { Promotion } from "src/app/ht-client-core/common/models/promotion";
import { PromotionDialogPage } from "src/app/ht-client-core/common/pages/promotion-dialog/promotion-dialog.page";
import { PopoverController } from "@ionic/angular";

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  iconPerType = {
    reservation_status_change: "assets/imgs/menu-reservation-history.svg",
    service_request: "assets/imgs/menu-service-history.svg",
    order_request: "assets/imgs/menu-order-update.svg",
    important_notice: "assets/imgs/menu-important_notice.svg",
    promotional: "assets/imgs/menu-promotional.svg",
    general_info: "assets/imgs/menu-general_info.svg"
  };

  constructor(
    private api: ApiService,
    private authService: AuthenticationService,
    private servicesService: ServicesService,
    private orderService: OrderService,
    private reservationService: ReservationService,
    private promotionService: PromotionService,
    private hotelService: HotelService,
    private router: Router,
    private requestService: RequestService,
    private popoverController: PopoverController
  ) {}

  getNotifications(readOnly) {
    //this.constantAddin + addin +

    let date = new Date();
    // date.setDate(date.getDate() - 30);
    let dateMom = moment(date).subtract(30, "days");
    return new Promise((resolve, reject) => {
      this.api
        .get(
          "notifications",
          {
            read_filter: readOnly,
            created_at_filter: dateMom.format("YYYY-MM-DD hh:mm")
          },
          {
            headers: {
              Authorization: "Bearer " + this.authService.getToken()
            }
          },
          false,
          false
        )
        .pipe(
          map((res) => {
            res["data"].forEach((element) => {
              element.created_at = element.created_at
                ? moment(element.created_at).calendar()
                : null;
              element.icon = this.iconPerType[element.type];
              element.body = element.body.replaceAll("\n", "<br/>");
            });
            return res;
          })
        )
        .subscribe(
          (res) => resolve(res),
          (err) => {
            if (err.status == 401 || err.status == 402) {
              // this.events.publish("user:logout");
            }
            reject(err);
          }
        );
    });
  }

  readNotification(notificationId) {
    //this.constantAddin + addin +
    return new Promise((resolve, reject) => {
      this.api
        .post("notifications/" + notificationId + "/read", null, {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        })
        .subscribe(
          (res) => resolve(res),
          (err) => {
            if (err.status == 401 || err.status == 402) {
              // this.events.publish("user:logout");
            }
            reject(err);
          }
        );
    });
  }

  startRequest(notificationData, user, booking) {
    switch (notificationData.objectable_type) {
      case "service":
        this.servicesService
          .getById(notificationData.objectable_id)
          .subscribe((service) => {
            const navigationExtras = {
              state: {
                service: service
              }
            };
            if (
              this.requestService.checkUnregistered(
                service,
                "concierge",
                user,
                booking
              )
            ) {
              return null;
            }
            this.router.navigate(["service/" + service.id], navigationExtras);
          });
        break;
      case "orderForm":
        this.orderService
          .getById(notificationData.objectable_id)
          .subscribe((orderForm) => {
            const navigationExtras = {
              state: {
                order: orderForm
              }
            };
            if (
              this.requestService.checkUnregistered(
                orderForm,
                "orders",
                user,
                booking
              )
            ) {
              return null;
            }

            this.router.navigate(["order/" + orderForm.id], navigationExtras);
          });
        break;
      case "reservable":
        this.reservationService
          .getById(notificationData.objectable_id)
          .subscribe((reservable) => {
            const navigationExtras = {
              state: {
                resId: reservable.id,
                type: "pages",
                reservationTitle: reservable.name,
                reservationForm: reservable.reservationForm,
                entitlement_badge_label: reservable.entitlement_badge_label,
                entitlement_badge_description:
                  reservable.entitlement_badge_description
              }
            };

            if (
              this.requestService.checkUnregistered(
                reservable,
                "reservations",
                user,
                booking
              )
            ) {
              return null;
            }

            this.router.navigate(
              ["reservation/" + reservable.id],
              navigationExtras
            );
          });
        break;
      case "page":
        const page = this.hotelService.findPageById(
          notificationData.objectable_id
        );
        console.log(page);
        const pageExtras: NavigationExtras = {
          state: {
            page: page
          }
        };
        if (page) {
          this.router.navigate(
            ["page-view/" + notificationData.objectable_id],
            pageExtras
          );
        }
        break;
      case "offer":
        this.promotionService
          .getById(notificationData.objectable_id)
          .subscribe((promotion) => {
            this.openPromotion(promotion, user, booking);
          });
        break;
    }
  }

  async openPromotion(promotion: Promotion, user, booking: Booking) {
    if (!!promotion.description) {
      const popover = await this.popoverController.create({
        component: PromotionDialogPage,
        cssClass: "promotion-popover",
        translucent: true,
        event: null,
        componentProps: {
          promotion: promotion,
          booking: booking,
          isAuth: !!user
        }
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();
      // console.log("onDidDismiss resolved with role", role);
    } else {
      if (!!promotion.offerable_id) {
        switch (promotion.offerable_type) {
          case "activity":
            this.router.navigate(["activity-details"], {
              state: {
                activity: promotion.offerable
              }
            });
            break;
          case "orderForm":
            this.orderService
              .getById(promotion.offerable_id)
              .toPromise()
              .then((request) => {
                this.requestService.startRequest(
                  request,
                  "order",
                  user,
                  booking
                );
              });
            break;
          case "reservable":
            this.reservationService
              .getById(promotion.offerable_id)
              .toPromise()
              .then((request) => {
                this.requestService.startRequest(
                  request,
                  "reservation",
                  user,
                  booking
                );
              });
            break;
          case "page":
            let navigationExtras: NavigationExtras = {
              state: {
                page: promotion.offerable
              }
            };
            this.router.navigate(
              ["page-view/" + promotion.offerable_id],
              navigationExtras
            );
            break;
        }
      }
    }
  }
}
