import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Page } from "../../models/page";
import { ApiService } from "../api/api.service";
import { Hotel } from "../../models/hotel";
import { AuthenticationService } from "../authentication/authentication.service";
import { Space } from "../../models/space";
import { AnalyticsService } from "../analytics/analytics.service";
@Injectable({
  providedIn: "root"
})
export class HotelService {
  hotel: Hotel;
  private hotelUpdated = new Subject<any>();
  constructor(
    private api: ApiService,
    private authService: AuthenticationService,
    private analytics: AnalyticsService
  ) {}

  getHotelUpdateListener() {
    return this.hotelUpdated.asObservable();
  }

  getHotel(cache: boolean = true) {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId(),
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus,pages.menus.orderForms,pages.children.menus.orderForms"
        },
        null,
        cache
      )
      .pipe(
        map((res) => {
          // console.log("hotel with pages", res);
          res["data"].pages.forEach((page) => {
            this.archivePage(page);
          });
          if (!!this.authService.user) {
            res["data"].pages = this.filterVIP(
              res["data"].pages,
              this.authService.booking.room
                ? this.authService.booking.room.id
                : null
            );
            res["data"].pages = this.filterDMC(
              res["data"].pages,
              this.authService.booking.dmc ? this.authService.booking.dmc : null
            );
          } else {
            res["data"].pages = this.filterVIP(res["data"].pages, null);
            res["data"].pages = this.filterDMC(res["data"].pages, null);
          }

          return res["data"];
        })
      )
      .subscribe((hotel: Hotel) => {
        return this.setHotel(hotel);
      });
  }

  setHotel(hotel: Hotel) {
    console.log("SETTING HOTEL", hotel);
    if (hotel) {
      if (!this.hotel) {
        this.hotel = hotel;
        this.analytics.setProperty("hotelId", this.hotel.id);

        this.hotelUpdated.next({ ...this.hotel });
      } else {
        this.hotel = hotel;
        this.analytics.setProperty("hotelId", this.hotel.id);

        this.hotelUpdated.next({ ...this.hotel });
      }
    }
  }
  archivePage(page) {
    if (page.children) {
      page.children.forEach((element) => {
        this.archivePage(element);
      });
    }
  }

  filterVIP(pages, room) {
    if (!room) {
      pages = pages.filter((a) => {
        if (a.room_ids) {
          return a.room_ids.length < 1;
        } else {
          return true;
        }
      });
    } else {
      pages = pages.filter((a) => {
        if (!a.room_ids) {
          return true;
        }
        return a.room_ids.length < 1 || a.room_ids.indexOf(room) !== -1;
      });
    }
    for (let index = 0; index < pages.length; index++) {
      const page = pages[index];
      if (page.reservability_room_ids) {
        if (!room) {
          if (page.reservability_room_ids.length > 0) {
            pages[index].isReservable = false;
          }
        } else {
          if (
            page.reservability_room_ids.length > 0 &&
            page.reservability_room_ids.indexOf(room) === -1
          ) {
            pages[index].isReservable = false;
          }
        }
      }
      page.children = this.filterVIP(page.children, room);
    }
    return pages;
  }

  filterDMC(pages, dmc) {
    if (!dmc) {
      pages = pages.filter((a) => {
        if (a.booking_dmc_whitelist) {
          return a.booking_dmc_whitelist.length < 1;
        } else {
          return true;
        }
      });
    } else {
      pages = pages.filter((a) => {
        if (!a.booking_dmc_whitelist) {
          return true;
        }
        return (
          a.booking_dmc_whitelist.length < 1 ||
          a.booking_dmc_whitelist.indexOf(dmc) !== -1
        );
      });
    }
    for (let index = 0; index < pages.length; index++) {
      const page = pages[index];
      page.children = this.filterDMC(page.children, dmc);
    }
    return pages;
  }
  getHotels(): Observable<Hotel[]> {
    return this.api
      .get(
        "guests/hotels",
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getPages(): Observable<Page[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId(),
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus,pages.menus.orderForms,pages.children.menus.orderForms"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"].pages["data"];
        })
      );
  }

  getSpaces(): Observable<Space[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/spaces",
        {
          include: "page,page.weekSchedule,page.reservationForm"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  async base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };
      reader.readAsDataURL(blob);
    });
  }

  findPageById(id): Page {
    var target = this.getNodeById(id, this.hotel.pages);
    // target.children = children;
    return target;
  }

  getNodeById(id, node) {
    var reduce = [].reduce;
    function runner(result, node) {
      if (result || !node) return result;
      return (
        (node.id === id && node) || //is this the proper node?
        runner(null, node.children) || //process this nodes children
        reduce.call(Object(node), runner, result)
      ); //maybe this is some ArrayLike Structure
    }
    return runner(null, node);
  }
}
