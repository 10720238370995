import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/ht-client-core/common/services/api/api.service";
import { AuthenticationService } from "src/app/ht-client-core/common/services/authentication/authentication.service";
import { Questionnaire } from "../../common/models/questionnaire";
import { FeedbackForm } from "../../common/models/feedback-form";
import { FeedbackConfig } from "../../common/models/feedback-config";
import { Feedback } from "../../common/models/feedback";
import { Preferences } from "@capacitor/preferences";
import * as moment from "moment-timezone";
import { Booking } from "../../common/models/booking";
import { TranslateService } from "@ngx-translate/core";
import { PopoverController } from "@ionic/angular";
import { NpsFollowUpComponent } from "../../common/components/nps-followup/nps-followup.component";
import { RequestConfirmationPage } from "../../requestsModule/pages/request-confirmation/request-confirmation.page";
const FAST_CHECKIN_KEY = "show_fc";
export const enum FEEDBACKTYPE {
  NPS = "nps",
  SERVICE = "service_request",
  RESERVATION = "reservation",
  ORDER = "order"
}
@Injectable({
  providedIn: "root"
})
export class QuestionnairesService {
  constructor(
    private api: ApiService,
    private authService: AuthenticationService,
    private translateService: TranslateService,
    private popoverCtrl: PopoverController
  ) {}

  getAll(): Observable<Questionnaire[]> {
    let filters = {
      "filters[forGuests]": true
    };
    if (!this.authService.getToken()) {
      filters["filters[forUnregisteredGuests]"] = true;
    }
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/questionnaires",
        filters,
        null,
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getById(id): Observable<Questionnaire> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/questionnaires/" + id,
        {},
        null,
        true,
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }
  getFastCheckin(): Observable<Questionnaire[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/questionnaires",
        {
          "filters[type]": "fast_checkin"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  submit(questionnaireId, input) {
    return this.api.post(
      "hotels/" +
        this.authService.getHotelId() +
        "/questionnaires/" +
        questionnaireId +
        "/input",
      {
        input: input
      },
      {
        headers: {
          Authorization: "Bearer " + this.authService.getToken()
        }
      }
    );
  }

  submitBookingQuestionnaire(questionnaireId, input) {
    return this.api.post(
      "booking/hotels/" +
        this.authService.getHotelId() +
        "/questionnaires/" +
        questionnaireId +
        "/input",
      {
        input: input
      },
      {
        headers: {
          Authorization: "Bearer " + this.authService.getToken()
        }
      }
    );
  }

  submitUnregistered(questionnaireId, input) {
    return this.api.post(
      "hotels/" +
        this.authService.getHotelId() +
        "/questionnaires/" +
        questionnaireId +
        "/input/unregistered",
      {
        input: input
      }
    );
  }

  getHistory(qId) {
    return this.api
      .get(
        "hotels/" +
          this.authService.getHotelId() +
          "/questionnaires/" +
          qId +
          "/input",
        null,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        }
      )
      .pipe(
        map((data, key) => {
          let res = data["data"];
          res.forEach((element) => {
            element.created_at = element.created_at
              ? moment(element.created_at, "YYYY-MM-DD HH:mm")
              : null;
          });
          return res;
        })
      );
  }

  async getHideFCstatus() {
    return await Preferences.get({ key: FAST_CHECKIN_KEY });
  }

  async setHideFCstatus(status) {
    return await Preferences.set({ key: FAST_CHECKIN_KEY, value: status });
  }

  getFeedbackForm(
    feedback_type: FEEDBACKTYPE,
    feedbackable_id: string
  ): Observable<FeedbackForm> {
    return this.api
      .get(
        "feedback/" + feedback_type + "/" + feedbackable_id + "/fields",
        {},
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        },
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getFeedbackConfig(
    feedback_type: FEEDBACKTYPE,
    feedbackable_id: string
  ): Observable<FeedbackConfig> {
    return this.api
      .get(
        "feedback/" + feedback_type + "/" + feedbackable_id + "/config",
        {},
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        },
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getFeedback(
    feedback_type: FEEDBACKTYPE,
    feedbackable_id: string
  ): Observable<Feedback> {
    if (!!feedback_type && !!feedbackable_id)
      return this.api
        .get(
          "feedback/" + feedback_type + "/" + feedbackable_id + "/show",
          {},
          {
            headers: {
              Authorization: "Bearer " + this.authService.getToken()
            }
          },
          false
        )
        .pipe(
          map((res) => {
            return res["data"];
          })
        );
  }

  submitFeedback(feedback_type: FEEDBACKTYPE, feedbackable_id: string, input) {
    return this.api.post(
      "feedback/" + feedback_type + "/" + feedbackable_id,
      {
        feedback: input
      },
      {
        headers: {
          Authorization: "Bearer " + this.authService.getToken()
        }
      }
    );
  }

  checkVisibility(booking: Booking, config: FeedbackConfig) {}

  async checkForFollowUp(rating: number, config: FeedbackConfig) {
    const buttons = [];
    if (config.positive_limit_urls && config.positive_limit <= rating) {
      config.positive_limit_urls.forEach((btn) => {
        buttons.push({
          text: btn.description,
          handler: () => {
            window.open(btn.url, "_system");
          }
        });
      });
      buttons.push({
        text: this.translateService.instant("FORMS.CANCEL"),
        role: "cancel"
      });

      // console.log("my buttons", buttons);
      const actionSheet = await this.popoverCtrl.create({
        component: NpsFollowUpComponent,
        componentProps: {
          config
        },
        cssClass: "nps-follow-up-popover",
        mode: "ios"
      });

      await actionSheet.present();

      const result = await actionSheet.onDidDismiss();
    } else {
      this.npsSuccessPopover(null);
    }
  }
  async npsSuccessPopover(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: RequestConfirmationPage,
      cssClass: "success-request-popover",
      event: ev,
      translucent: true,
      componentProps: {
        type: "questionnaire"
      }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }
}
