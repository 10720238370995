import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "filterByEvCat"
})
export class FilterByEvCatPipe implements PipeTransform {
  transform(value: any[], categoryId: string): any[] {
    if (!!categoryId) {
      return value.filter(
        (i) => this.getObjFromId(i.activity.categories, categoryId) !== -1
      );
    } else {
      return value;
    }
  }
  getObjFromId(jarray: any[], key: any) {
    for (let i = 0; i < jarray.length; i++) {
      if (jarray[i].id === key) {
        return i;
      }
    }
    return -1;
  }
}
