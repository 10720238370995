import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ApiService } from "src/app/ht-client-core/common/services/api/api.service";
import { AuthenticationService } from "src/app/ht-client-core/common/services/authentication/authentication.service";
export enum KNXSPECTYPES {
  ROOM = "room",
  PAGE = "page",
  ACTIVITY = "activity"
}

@Injectable({
  providedIn: "root"
})
export class SmartControlService {
  authToken;
  url;
  constructor(
    private api: ApiService,
    private authService: AuthenticationService
  ) {}

  getSpecs(
    id,
    type: KNXSPECTYPES
  ): Observable<{ lights: any[]; scenes: any[]; clima: any }> {
    return this.api
      .get(
        "knx/" + type + "/" + id + "/specs",
        {},
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        },
        true,
        true
      )
      .pipe(
        map((res) => {
          if (res) {
            switch (type) {
              case KNXSPECTYPES.ROOM:
                const lights = res.filter((a) => {
                  return (
                    a.function === "L" ||
                    a.function === "SH" ||
                    a.function === "D" ||
                    a.function === "DS" ||
                    a.function === "DIN"
                  );
                });
                const doors = res.filter((a) => {
                  return a.function === "DOOR" || a.function === "DOORNR";
                });
                const shades = res.filter((a) => {
                  return a.function === "CW" || a.function === "CB";
                });
                const scenes = res.filter((a) => {
                  return a.function === "SCENE";
                })[0];
                const cflItems = res.filter((a) => {
                  return a.function === "C/F/L";
                });
                const clima = {
                  ACP: res.filter((a) => {
                    return a.function.indexOf("ACP") !== -1;
                  })[0],
                  ACF: res.filter((a) => {
                    return a.function.indexOf("ACF") !== -1;
                  })[0],
                  ACT: res.filter((a) => {
                    return a.function.indexOf("ACT") !== -1;
                  })[0]
                };
                return {
                  lights,
                  doors,
                  shades,
                  scenes,
                  cflItems,
                  clima
                };
              case KNXSPECTYPES.PAGE:
                return res;
              case KNXSPECTYPES.ACTIVITY:
                return res;
            }
          }
        })
      );
  }

  getDataPoint(id, type, datapointId) {
    return this.api
      .get(
        "knx/" + type + "/" + id + "/proxy",
        {},
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
            "x-url": "/rest/datapoints/" + datapointId
          }
        },
        false,
        true
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  setDataPoint(id, type, datapointId, value) {
    return this.api
      .put(
        "knx/" + type + "/" + id + "/proxy",
        value,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
            "x-url": "/rest/datapoints/" + datapointId
          }
        },
        true
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getDataPointValues(id, type) {
    return this.api
      .get(
        "knx/" + type + "/" + id + "/proxy",
        {},
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
            "x-url": "/rest/datapoints/values"
          }
        },
        false,
        true
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
