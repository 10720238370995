import { Component, OnInit } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { FieldArrayType, FormlyFormBuilder } from "@ngx-formly/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-simple-group",
  templateUrl: "./simple-group.component.html",
  styleUrls: ["./simple-group.component.scss"]
})
export class SimpleGroupComponent extends FieldArrayType {
  constructor(builder: FormlyFormBuilder) {
    super(builder);
  }
}
