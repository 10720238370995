import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { FeedbackConfig } from "../../models/feedback-config";
import {
  AnalyticsService,
  EVENTORIGIN,
  EVENTTYPES
} from "../../services/analytics/analytics.service";
import { HotelService } from "../../services/hotel/hotel.service";

@Component({
  selector: "app-nps-followup",
  templateUrl: "./nps-followup.component.html",
  styleUrls: ["./nps-followup.component.scss"]
})
export class NpsFollowUpComponent implements OnInit {
  @Input() config: FeedbackConfig;
  constructor(
    private analyticsService: AnalyticsService,
    private hotelService: HotelService
  ) {}

  ngOnInit() {}

  openUrl(url) {
    this.analyticsService.logEvent(EVENTTYPES.OPEN_EXTERNAL, {
      id: url,
      hotelId: this.hotelService.hotel,
      origin: EVENTORIGIN.DASHBOARD
    });
    window.open(url, "_blank");
  }

  dismiss(save) {
    // this.popoverCtrl.dismiss({ save: save, comment: this.comment });
  }
}
