import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-reservation-history-selector",
  templateUrl: "./reservation-history-selector.component.html",
  styleUrls: ["./reservation-history-selector.component.scss"]
})
export class ReservationHistorySelectorComponent implements OnInit {
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {}

  dismiss(type) {
    this.popoverCtrl.dismiss({ type: type });
  }
}
