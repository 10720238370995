import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat
} from "ngx-intl-tel-input";

@Component({
  selector: "app-custom-phone-num",
  templateUrl: "./custom-phone-num.component.html",
  styleUrls: ["./custom-phone-num.component.scss"]
})
export class CustomPhoneNumComponent extends FieldType {
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  input = document.querySelector("#phone");
}
