import { Directive, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';
import { timeout } from 'rxjs/operators';

@Directive({
  selector: '[appHideHeader]'
})
export class HideHeaderDirective implements OnInit {

  @Input('appHideHeader') toolbar: any;

  private toolbarHeight = 44;

  constructor(private renderer: Renderer2, private domCtrl: DomController) {

  }

  ngOnInit() {
    // console.log('whole toolbar', this.toolbar);
    this.toolbar = this.toolbar.el;
    setTimeout(() => {
      this.domCtrl.read(() => {
        // console.log('el toolbar', this.toolbar);

        this.toolbarHeight = this.toolbar.clientHeight;
      })
    }, 100)
  }

  @HostListener('ionScroll', ['$event']) onContentScroll($event) {
    // console.log($event);
    const scrollTop = $event.detail.scrollTop;
    let newPosition = (scrollTop / 5);
    let newOpacity = (newPosition / this.toolbarHeight);

    // console.log('newOpacity', newPosition, this.toolbarHeight, newOpacity);

    this.domCtrl.write(() => {
      // this.renderer.setStyle(this.toolbar, 'top', `${newPosition}px`);
      this.renderer.setStyle(this.toolbar, 'background', `rgba(0,0,0,${newOpacity})`);
    })
  }


}