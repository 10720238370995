import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-custom-photo-gallery',
  templateUrl: './custom-photo-gallery.component.html',
  styleUrls: ['./custom-photo-gallery.component.scss'],
})
export class CustomPhotoGalleryComponent extends FieldType {

}
