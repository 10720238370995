import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { FieldType } from "@ngx-formly/core";
import { IonicSelectableComponent } from "ionic-selectable";
import { CustomSelectComponent } from "../custom-select/custom-select.component";

@Component({
  selector: "app-custom-radio",
  templateUrl: "./custom-radio.component.html",
  styleUrls: ["./custom-radio.component.scss"],
})
export class CustomRadioComponent extends FieldType {
  constructor(private popoverCtrl: PopoverController) {
    super();
  }
  itemChange(event: { component: IonicSelectableComponent; value: any }) {
    console.log("item:", event.value);
  }

  async presentPopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: CustomSelectComponent,
      cssClass: "my-custom-class",
      event: ev,
      translucent: true,
      componentProps: {
        field: this.field,
      },
    });
    await popover.present();
    popover.onDidDismiss().then((res) => {
      console.log(res);

      if (res.data) {
        super.model[this.field.key.toString()] = res.data;
        super.formControl.setValue(res.data);
        console.log(super.model, this.model);
      }
    });
  }
}
