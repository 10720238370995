import { Injectable } from "@angular/core";
const PUSH_CONSENT = "ht-onesignal_push_consent";
// import { OneSignal } from "@awesome-cordova-plugins/onesignal/ngx";
import OneSignal from "onesignal-cordova-plugin";
import { AlertController, Platform } from "@ionic/angular";
import { Preferences } from "@capacitor/preferences";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { LowerCasePipe } from "@angular/common";
import { NavigationExtras, Router } from "@angular/router";
@Injectable({
  providedIn: "root"
})
export class PushService {
  constructor(
    private alertCtrl: AlertController,
    private platform: Platform,
    private translate: TranslateService,
    private lowercasePipe: LowerCasePipe,
    private router: Router
  ) {}

  setUserConsent(consent) {
    Preferences.set({ key: PUSH_CONSENT, value: consent });
  }
  getUserConsent() {
    return Preferences.get({ key: PUSH_CONSENT });
  }
  init() {
    OneSignal.setAppId(environment.pushAppId);
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      let notificationData = JSON.stringify(jsonData);
      // console.log("notificationOpenedCallback: " + notificationData);
    });
    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      // console.log("User accepted notifications: " + accepted);
    });
    OneSignal.addSubscriptionObserver(async (event) => {
      // console.log("event.to.isSubscribed: " + event.to.isSubscribed);

      if (event.to.isSubscribed) {
        await OneSignal.getDeviceState(async (resp) => {
          // console.log("onesignal_user_id: " + resp.userId);
          await Preferences.set({
            key: "onesignal_user_id",
            value: resp.userId
          });
        });
      }
    });
    OneSignal.setNotificationWillShowInForegroundHandler(
      async (notificationReceivedEvent) => {
        console.log("notificationReceivedEvent", notificationReceivedEvent);
        setTimeout(async () => {
          const notificationData = notificationReceivedEvent.getNotification();
          console.log("received", notificationData);
          let additionalData = notificationData.additionalData;
          if (additionalData["objectable_type"]) {
            additionalData["objectable_type"] = this.lowercasePipe.transform(
              additionalData["objectable_type"].split("\\").reverse()[0]
            );
            console.log("edit result", additionalData["objectable_type"]);
          }
          console.log("edit result", additionalData);

          let alert = await this.alertCtrl.create({
            header: notificationData.title,
            subHeader: notificationData.body,
            buttons: [
              {
                text: this.translate.instant("GENERAL.VIEW_NOTIFICATION"),
                handler: () => {
                  const mockNotification = {
                    title: notificationData.title,
                    body: notificationData.body,
                    data: additionalData
                  };
                  let navigationExtras: NavigationExtras = {
                    state: {
                      notification: mockNotification
                    }
                  };
                  console.log(mockNotification);
                  this.router.navigate(
                    ["notification/" + notificationData.notificationId],
                    navigationExtras
                  );
                  return true;
                }
              },
              {
                text: this.translate.instant("GENERAL.MAYBE_LATER")
              }
            ]
          });

          alert.onDidDismiss().then(() => {
            notificationReceivedEvent.complete(notificationData);
          });
          await alert.present();
        }, 1000);
      }
    );
    OneSignal.setNotificationOpenedHandler(async (notification) => {
      console.log("OneSignal: notification opened:", notification);
      setTimeout(async () => {
        const notificationData = notification.notification;
        let additionalData = notificationData.additionalData;
        if (additionalData["objectable_type"]) {
          additionalData["objectable_type"] = this.lowercasePipe.transform(
            additionalData["objectable_type"].split("\\").reverse()[0]
          );
        }
        const alert = await this.alertCtrl.create({
          header: notificationData.title,
          subHeader: notificationData.body,
          buttons: [
            {
              text: this.translate.instant("GENERAL.VIEW_NOTIFICATION"),
              handler: () => {
                const mockNotification = {
                  title: notificationData.title,
                  body: notificationData.body,
                  data: additionalData
                };
                let navigationExtras: NavigationExtras = {
                  state: {
                    notification: mockNotification
                  }
                };
                console.log(mockNotification);
                this.router.navigate(
                  ["notification/" + notificationData.notificationId],
                  navigationExtras
                );
                return true;
              }
            },
            {
              text: this.translate.instant("GENERAL.MAYBE_LATER")
            }
          ]
        });
        await alert.present();
      }, 1000);
    });
  }

  async getDeviceState() {
    const deviceState = await this.getDeviceStatePromise();

    return deviceState;
  }

  private getDeviceStatePromise() {
    return new Promise((resolve, reject) => {
      (OneSignal as any).getDeviceState((state: any) => resolve(state));
    });
  }

  async notificationAlert(title, message) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: message
    });
    alert.present();
  }
}
