import { registerLocaleData } from "@angular/common";
import { Injectable } from "@angular/core";
import { Preferences } from "@capacitor/preferences";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class LanguageService {
  constructor() {}

  getLang() {
    return Preferences.get({ key: "lang" });
  }

  setLang(lang) {
    return Preferences.set({ key: "lang", value: lang });
  }

  getAvailLangs() {
    return new Promise<string[]>((resolve) => {
      Preferences.get({ key: "availableLang" }).then((languages) => {
        if (languages.value) {
          resolve(languages.value.split(","));
        } else {
          resolve(environment.languages);
        }
      });
    });
  }

  setAvailLangs(langs) {
    // if (langs) {
    //   langs.forEach((lang) => {
    //     registerLocaleData("@angular/common/locales/" + lang, lang);
    //   });
    // }
    return Preferences.set({ key: "availableLang", value: langs });
  }

  async resetLangs() {
    await Preferences.remove({ key: "availableLangs" });
  }
}
