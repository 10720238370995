import { Pipe, PipeTransform } from "@angular/core";
import { MenuItemExtraOption } from "../../models/menu-item-extra-option";

@Pipe({
  name: "searchFilter"
})
export class SearchFilterPipe implements PipeTransform {
  transform(value: any[], searchTerm: string): any[] {
    if (!!searchTerm) {
      return value.filter(
        (i) => i.name.toUpperCase().indexOf(searchTerm.toUpperCase()) !== -1
      );
    } else {
      return value;
    }
  }
}
