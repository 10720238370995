import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "app-custom-label",
  templateUrl: "./custom-label.component.html",
  styleUrls: ["./custom-label.component.scss"]
})
export class CustomLabelComponent extends FieldType {
  constructor(private sanitizer: DomSanitizer) {
    super();
  }
  sanitizeHTML(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
