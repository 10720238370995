import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { RatingService } from "../rating/rating";
// import { ImagePicker } from '@ionic-native/image-picker/ngx';

// import { GalleryModal } from 'ionic-gallery-modal';
import * as moment from "moment-timezone";
import { PopoverController, ToastController } from "@ionic/angular";
import { PhotoServiceProvider } from "../photo-service/photo-service";
import { TooltipMessageComponent } from "../../components/tooltip-message/tooltip-message.component";
import { UtilsService } from "../utils/utils.service";
import { FormGroup } from "@angular/forms";
import { environment } from "src/environments/environment";

/*
  Generated class for the FormsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class FormsProvider {
  timezone = environment.timezone;
  constructor(
    private translate: TranslateService,
    private ratingService: RatingService,
    /*private imagePicker: ImagePicker,  */
    private photoService: PhotoServiceProvider,
    private popoverController: PopoverController,
    private utilities: UtilsService,
    private toastCtrl: ToastController
  ) {
    //// console.log('Hello FormsProvider Provider');
  }

  generateField(field, conditions) {
    let formlyField = null;

    if (!field.type) {
      formlyField = {
        key: field.id ? field.id : field.field,
        type: "general-input",
        defaultValue: field.value
          ? field.value
          : field.default
          ? field.default
          : "",
        previewValue: field.value ? field.value : "",
        className: field.type,
        hideExpression: conditions,
        modelOptions: {
          debounce: {
            default: 2000
          }
        },
        templateOptions: {
          type: field.type == "text" ? "text" : "number",
          label: field.label,
          placeholder: field.label,
          required: field.required,
          min: field.extra ? field.extra.min : null,
          max: field.extra ? field.extra.max : null,
          alert_above: field.extra ? field.extra.alert_above : null,
          alert_below: field.extra ? field.extra.alert_below : null,
          step: field.extra ? field.extra.step : null,
          tooltip: async (ev: any) => {
            const popover = await this.popoverController.create({
              component: TooltipMessageComponent, //field.hint ? field.hint[this.translate.getDefaultLang()] : null,
              cssClass: "input-tooltip",
              event: ev,
              translucent: true
            });
            popover.present();
          }
        }
      };
    } else {
      if (["label", "page_break", "html"].indexOf(field.type) !== -1) {
        formlyField = {
          key: field.id ? field.id : field.field,
          noFormControl: true,
          type: "customLabel",
          className: "custom-label",
          templateOptions: {
            type: "customLabel",
            label: field.label,
            htmlEnabled: field.type === "html"
          }
          // template: '<ion-item class="form-label" color="primary" text-wrap >' + field.label + '</ion-item>',
        };
      } else if (
        ["text", "integer", "float", "email"].indexOf(field.type) !== -1
      ) {
        //// console.log(field);
        formlyField = {
          key: field.id ? field.id : field.field,
          type: "general-input",
          name: field.id ? field.id : field.field,
          defaultValue: field.value
            ? field.value
            : field.default
            ? field.default
            : "",
          previewValue: field.value ? field.value : "",
          hideExpression: conditions,
          className: "custom-general-input",
          templateOptions: {
            type: field.type,
            label: field.label,
            placeholder: "",
            requiredError: this.translate.instant("FORMS.FIELD_IS_REQUIRED"),
            required: field.required,
            tooltip: async (ev: any) => {
              const popover = await this.popoverController.create({
                component: TooltipMessageComponent,
                componentProps: {
                  hint: field.hint
                    ? field.hint[this.translate.getDefaultLang()]
                    : null
                },
                cssClass: "input-tooltip",
                event: ev,
                translucent: true
              });
              popover.present();
            }
          }
          // validators: {
          //   validation: field.required ? Validators.compose([Validators.required]) : null
          // },
          // validation: {
          //   messages: {
          //     required: (error, formlyfield) => this.translate.instant('REQUIRED_FIELD')
          //   },
          // },
        };
        formlyField.validators = {};
        if (formlyField.templateOptions.min) {
          formlyField.validators.min = {
            expression: function ($viewValue, $modelValue) {
              var value = $modelValue || $viewValue;
              if (value) {
                return value > formlyField.templateOptions.min;
              }
              return true;
            },
            message: this.translate.instant("FORMS_MIN_ERROR", {
              reqValue: formlyField.templateOptions.min
            })
          };
        }
        if (formlyField.templateOptions.max) {
          formlyField.validators.max = {
            expression: function ($viewValue, $modelValue) {
              var value = $modelValue || $viewValue;
              if (value) {
                return value > formlyField.templateOptions.max;
              }
              return true;
            },
            message: this.translate.instant("FORMS_MAX_ERROR", {
              reqValue: formlyField.templateOptions.max
            })
          };
        }
        if (field.type === "integer") {
          formlyField.templateOptions.type = "number";
        } else if (field.type === "float") {
          formlyField.templateOptions.type = "number";
        } else if (field.type === "phone_num") {
          formlyField.templateOptions.type = "tel";
        }
      } else {
        switch (field.type) {
          case "phone_num":
            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type: "customPhoneNum",
              defaultValue: null,
              previewValue: field.value != undefined ? field.value : 0,
              className: "custom-phone-num",
              templateOptions: {
                label: field.label,
                required: field.required,
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null
              },
              validation: {
                messages: {
                  required: (error, formlyfield) =>
                    this.translate.instant("FORMS.FIELD_IS_REQUIRED"),
                  validatePhoneNumber: (error, formlyfield) =>
                    this.translate.instant("FORMS.INVALID_PHONE_NUMBER")
                }
              }
            };
            break;
          case "textarea":
            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type: "customTextarea",
              defaultValue: field.value
                ? field.value
                : field.default
                ? field.default
                : "",
              previewValue: field.value ? field.value : "",
              className: "custom-textarea",
              templateOptions: {
                placeholder: "",
                label: field.label,
                required: field.required,
                maxlength: field.maxlength ? field.maxlength : 250,
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null
              },
              validation: {
                messages: {
                  required: (error, formlyfield) =>
                    this.translate.instant("FORMS.FIELD_IS_REQUIRED")
                }
              }
            };
            break;
          case "checkbox":
            let checked =
              typeof field.value !== undefined
                ? field.value
                : typeof field.default !== undefined
                ? field.default
                : false;
            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type: "customCheckbox",
              defaultValue: checked,
              previewValue: field.value != undefined ? field.value : 0,
              className: "custom-checkbox",
              templateOptions: {
                checked: checked,
                type: "checkbox",
                label: field.label,
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null,
                required: field.required || false
              },
              validation: {
                messages: {
                  required: (error, formlyfield) =>
                    this.translate.instant("FORMS.FIELD_IS_REQUIRED")
                }
              }
            };
            break;
          case "date":
            let date, previewDate;
            if (field.value) {
              //   // console.log(field.value);
              previewDate = this.translateDate(field.value);
            }
            if (field.default) {
              date = this.translateDate(field.default);
            }
            if (field.value) {
              // console.log(field.value);
              date = this.translateDate(field.value);
            } else if (field.default) {
              date = this.translateDate(field.default);
            }
            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type: "date",
              defaultValue: date,
              previewValue: previewDate,
              className: "date",
              templateOptions: {
                type: "date",
                label: field.label,
                defaultValue: date,
                placeholder: "",
                dateFormat: "DD MMM YYYY ",
                pickerFormat: "DD MMM YYYY ",
                datepickerPopup: "yyyy",
                date: true,
                time: false,
                mondayFirst: true,
                seconds: false,
                amPm: false,
                timezone: this.timezone,
                required: field.required,
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null
              },
              validation: {
                messages: {
                  required: (error, formlyfield) =>
                    this.translate.instant("FORMS.FIELD_IS_REQUIRED")
                }
              }
            };
            break;
          case "datetime":
            let datetime, previewDatetime;
            if (field.value) {
              // console.log(field.value);
              previewDatetime = this.translateDateTime(field.value);
            }
            if (field.default) {
              datetime = this.translateDateTime(field.default);
            } else {
              datetime = "";
            }
            // console.log("default datetime", datetime);
            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type: "date",
              defaultValue: datetime,
              previewValue: previewDatetime,
              className: "datetime",
              templateOptions: {
                type: "date",
                label: field.label,
                defaultValue: datetime,
                placeholder: "",
                dateFormat: "DD MMM YYYY HH:mm",
                pickerFormat: "DD MMM YYYY HH mm",
                date: true,
                time: true,
                mondayFirst: true,
                seconds: false,
                amPm: false,
                required: field.required,
                myInitialTime: this.translateDateTime(
                  moment().tz(this.timezone).toISOString()
                ),
                timezone: this.timezone,
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null
              },
              validation: {
                messages: {
                  required: (error, formlyfield) =>
                    this.translate.instant("FORMS.FIELD_IS_REQUIRED")
                }
              }
            };
            break;
          case "time":
            let time, previewTime;
            if (field.value) {
              //   console.log(field.value);
              previewTime = this.translateDateTime(field.value);
            }
            if (field.default) {
              time = this.translateDateTime(field.default);
            }
            // console.log('datetime', datetime);
            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type: "date",
              defaultValue: time,
              previewValue: previewTime,
              className: "time",
              templateOptions: {
                type: "date",
                label: field.label,
                defaultValue: time,
                placeholder: "",
                dateFormat: "HH:mm",
                pickerFormat: "HH mm",
                date: true,
                time: true,
                mondayFirst: true,
                seconds: false,
                amPm: false,
                required: field.required,
                timezone: this.timezone,
                myInitialTime: moment().tz(this.timezone),
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null
              },
              validation: {
                messages: {
                  required: (error, formlyfield) =>
                    this.translate.instant("FORMS.FIELD_IS_REQUIRED")
                }
              }
            };
            break;
          case "range":
            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type: "customRange",
              defaultValue: field.default
                ? Math.round(field.default / field.extra.step)
                : 0,
              previewValue: field.value
                ? Math.round(
                    field.value / field.extra.step -
                      Number.parseInt(field.extra.from)
                  )
                : null,
              className: "customRange",
              templateOptions: {
                label: field.label,
                rangeClass: "calm",
                min: Number.parseInt(field.extra.from),
                max:
                  field.extra.step < 1 || Number.parseInt(field.extra.from) < 0
                    ? Math.round(
                        (field.extra.to - Number.parseInt(field.extra.from)) /
                          field.extra.step
                      )
                    : field.extra.to,
                alert_above: field.extra ? field.extra.alert_above : null,
                alert_below: field.extra ? field.extra.alert_below : null,
                step: field.extra.step,
                displayMax: field.extra.to,
                // "minIcon": "ion-android-star-outline",
                // "maxIcon": "ion-android-star",
                required: field.required,
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null
              },
              validation: {
                messages: {
                  required: (error, formlyfield) =>
                    this.translate.instant("FORMS.FIELD_IS_REQUIRED")
                }
              }
            };
            break;
          case "rating":
            let defaultValue = field.default
              ? field.extra.step < 1
                ? field.default / field.extra.step
                : null
              : null;
            let stars = this.ratingService.calculateStars(defaultValue);
            // console.log(defaultValue);
            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type: "rating",
              defaultValue: defaultValue,
              previewValue: field.value,
              className: "rating",
              templateOptions: {
                label: field.label,
                stars: stars,
                required: field.required,
                step: field.extra.step,
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null,
                updateCallBack: (val, templateOptions) => {
                  // console.log("start values", templateOptions.step, val);
                  val =
                    templateOptions.step < 1
                      ? (val * templateOptions.step).toFixed(1)
                      : val;
                  templateOptions.stars =
                    this.ratingService.calculateStars(val);
                  // console.log("rating value " + val);
                }
              }
            };
            break;
          case "list":
            let options = [];
            let defaultValues = [];
            let previewValues = [];

            options = Object.keys(field.extra.values).map((key) => {
              return {
                name: field.extra.values[key],
                label: field.extra.values[key],
                icon: field.extra.icons
                  ? field.extra.icons[key].split("fa-")[1]
                  : null,
                value: key
              };
            });
            // options.sort((a, b) => {
            //   if (a.name < b.name) {
            //     return -1;
            //   }
            //   if (a.name > b.name) {
            //     return 1;
            //   }
            //   return 0;
            // });
            // console.log(options);
            if (field.value !== undefined && field.value !== null) {
              if (Array.isArray(field.value)) {
                field.value.forEach((entry) => {
                  if (field.extra.multiple) {
                    previewValues.push(entry);
                  } else {
                    previewValues.push({
                      name: field.extra.values[entry],
                      label: field.extra.values[entry],
                      icon: field.extra.icons
                        ? field.extra.icons[entry].split("fa-")[1]
                        : null,
                      value: entry
                    });
                  }
                });
              } else {
                if (field.extra.multiple) {
                  previewValues.push(field.value);
                } else {
                  previewValues.push({
                    name: field.extra.values[field.value],
                    label: field.extra.values[field.value],
                    icon: field.extra.icons
                      ? field.extra.icons[field.value].split("fa-")[1]
                      : null,
                    value: field.value
                  });
                }
              }
            }
            if (field.default !== undefined && field.default !== null) {
              if (Array.isArray(field.default)) {
                field.default.forEach((element) => {
                  if (field.extra.multiple) {
                    defaultValues.push(element);
                  } else {
                    defaultValues.push({
                      name: field.extra.values[element],
                      label: field.extra.values[element],
                      icon: field.extra.icons
                        ? field.extra.icons[element].split("fa-")[1]
                        : null,
                      value: element
                    });
                  }
                });
              } else {
                if (field.extra.multiple) {
                  defaultValues.push(field.default);
                } else {
                  defaultValues.push({
                    name: field.extra.values[field.default],
                    label: field.extra.values[field.default],
                    icon: field.extra.icons
                      ? field.extra.icons[field.default]
                        ? field.extra.icons[field.default].split("fa-")[1]
                        : null
                      : null,
                    value: field.default
                  });
                }
              }
            }

            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type:
                field.extra.display == "row"
                  ? "customRadioInline"
                  : "customRadio", //field.extra.multiple ? "select" : && !field.extra.multiple
              className: "select",
              defaultValue: defaultValues,
              previewValue: previewValues,
              multiple: field.extra.multiple,
              required: field.required,
              templateOptions: {
                label: field.label,
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null,
                globalListType: field.globalListType,
                options: options,
                withIcons: !!options[0].icon,
                multiselect: field.extra.multiple,
                multiple: field.extra.multiple,
                required: field.required,
                defaultValue: defaultValues,
                rawOptions: field.extra.values
              },
              validation: {
                messages: {
                  required: (error, formlyfield) =>
                    this.translate.instant("FORMS.FIELD_IS_REQUIRED")
                }
              }
            };
            break;
          case "photo_gallery":
            let defVal = [];
            // console.log('photo gallery', field.value ? field.value : (field.default ? field.default : []));

            formlyField = {
              hideExpression: conditions,
              key: field.id ? field.id : field.field,
              type: "photoGallery",
              defaultValue: field.value
                ? field.value
                : field.default
                ? field.default
                : [],
              previewValue: field.value ? field.value : [],
              templateOptions: {
                label: field.label,
                required: field.required,
                hint: field.hint
                  ? field.hint[this.translate.getDefaultLang()]
                  : null,
                urls: field.value
                  ? field.value
                  : field.default
                  ? field.default
                  : [],
                previewButton: this.translate.instant("FORMS.PREVIEW"),
                onSelectFile: (event, templateOptions) => {
                  if (event.target.files && event.target.files[0]) {
                    var filesAmount = event.target.files.length;
                    for (let i = 0; i < filesAmount; i++) {
                      var reader = new FileReader();
                      reader.onload = (event: any) => {
                        // console.log(event.target.result);
                        templateOptions.urls.push(event.target.result);
                      };

                      reader.readAsDataURL(event.target.files[i]);
                    }
                  }
                },
                choosePhoto: async (templateOptions) => {
                  let buttons = [
                    {
                      text: this.translate.instant("FORMS.FROM_CAMERA"),
                      handler: () => {
                        if (!templateOptions.urls) {
                          templateOptions.urls = [];
                        }
                        this.photoService.takePhoto().then((image) => {
                          templateOptions.urls.push(image);
                        });
                      }
                    },
                    {
                      text: this.translate.instant("FORMS.FROM_LIBRARY"),
                      handler: () => {
                        let options = {
                          maximumImagesCount: 10,
                          width: 800,
                          outputType: 1
                        };
                        if (!templateOptions.urls) {
                          templateOptions.urls = [];
                        }
                        // this.imagePicker.getPictures(options).then((results) => {
                        //   for (var i = 0; i < results.length; i++) {
                        //     templateOptions.urls.push('data:image/jpeg;base64,' + results[i]);
                        //   }
                        // }, (err) => { });
                      }
                    },
                    {
                      text: this.translate.instant("FORMS.CANCEL"),
                      role: "cancel"
                    }
                  ];
                  this.photoService.takePhoto().then((image) => {
                    templateOptions.urls.push(image);
                  });
                },
                showPhotoPreview: (templateOptions) => {
                  // console.log(templateOptions.urls);
                  let photos = [];
                  templateOptions.urls.forEach((element) => {
                    photos.push({
                      url: element
                    });
                  });
                }
              }
            };
            break;
        }
      }
    }

    //// console.log(JSON.stringify(formlyField));

    // if (formlyField) {
    //   if (typeof field.value !== 'undefined') {
    //     formlyField.defaultValue = field.value;
    //     // formlyField.templateOptions.disabled = true;
    //   }
    //   // formlyFields.push(formlyField);
    // }
    // console.log(formlyField);

    return formlyField;
  }

  getFormlyFields(form_fields, groups) {
    var formlyFields = [];
    let conditions;
    let multiselects = [];
    let enabledPageBreaks = false;
    // console.log("form_fields start", form_fields);
    // let result = this.detectPagebreaks(form_fields);
    // form_fields = result.fields;
    // groups = result.groups;
    // console.log(form_fields, groups);
    let pageBreaks = form_fields.filter((a) => {
      return a.type == "page_break";
    });
    if (!!pageBreaks) {
      if (pageBreaks.length > 0) {
        if (form_fields[form_fields.length - 1].type != "page_break") {
          let lastBreak = JSON.parse(JSON.stringify(pageBreaks[0]));
          lastBreak.id = "lastBreak";
          form_fields.push(lastBreak);
        }
        enabledPageBreaks = true;
        // console.log("adding pagebreak");
      }
    }
    // let pageBreaks = [];
    // form_fields.forEach((field, index) => {
    //   if(field.type == 'page_break'){
    //     pageBreaks.push(index);
    //   }
    // });
    let pageFields = [];
    form_fields.forEach((field, index) => {
      let formlyField = null;

      // console.log(field);

      if (field.type === "page_break") {
        // console.log("page break, grouping current fields");
        formlyFields.push({
          // name: field.id,
          // key: field.id,
          type: "simpleGroup", //"simpleGroup",
          fieldArray: {
            fieldGroupClassName: "row",
            templateOptions: {},
            fieldGroup: pageFields
          },
          fieldGroup: pageFields,
          showTitle: false
        });

        pageFields = [];
      } else {
        if (field.conditions) {
          conditions = "";

          field.conditions.forEach((condition) => {
            if (condition.field && condition.relation && condition.fieldValue) {
              if (conditions !== "") {
                conditions += " && ";
              }
              let refField =
                form_fields[
                  this.utilities.findObjWithAttr(
                    form_fields,
                    "id",
                    condition.field
                  )
                ];
              if (refField.type === "list") {
                conditions = (model: any, formState: any) => {
                  if (model[condition.field]) {
                    let matchCheck = condition.fieldValue.filter((element) =>
                      model[condition.field].includes(element)
                    );
                    return !(matchCheck.length > 0);
                  } else {
                    return true;
                  }
                };
              } else {
                conditions = (model: any, formState: any) => {
                  switch (condition.relation) {
                    case "in":
                      return (
                        model[condition.field] < condition.fieldValue[0] ||
                        model[condition.field] > condition.fieldValue[1] ||
                        model[condition.field] == ""
                      );
                    case ">":
                      return model[condition.field] <= condition.fieldValue;
                    case ">=":
                      return model[condition.field] < condition.fieldValue;
                    case "==":
                      return model[condition.field] != condition.fieldValue;
                    case "!=":
                      return model[condition.field] == condition.fieldValue;
                    case "<":
                      return model[condition.field] >= condition.fieldValue;
                    case "<=":
                      return model[condition.field] > condition.fieldValue;
                    default:
                      return true;
                  }
                };
              }
            }
          });
        }
        let grpfield = this.generateField(field, conditions);
        // // console.log(grpfield);
        if (field.group && field.group !== "") {
          let item;
          if (enabledPageBreaks) {
            item = pageFields.filter((subfield) => {
              return subfield.name === field.group;
            })[0];

            // console.log("SAME GROUP IN PAGEFIELDS", item);
          } else {
            item = formlyFields.filter(
              (subfield) => subfield.name === field.group
            )[0];
          }
          let group = groups.filter((grp) => grp.id === field.groupId)[0];
          if (item) {
            item.fieldArray.fieldGroup.push(grpfield);
          } else {
            if (enabledPageBreaks) {
              pageFields.push({
                name: field.group,
                key: field.groupId,
                type: "repeatGroup", //"simpleGroup",
                fieldArray: {
                  fieldGroupClassName: "row",
                  templateOptions: {
                    btnText: this.translate.instant("ADD"),
                    repeatability: group ? group.repeatability : null
                  },
                  fieldGroup: [grpfield]
                },
                // fieldGroup: [grpfield],
                showTitle: true
              });
            } else {
              formlyFields.push({
                name: field.group,
                key: field.groupId,
                type: "repeatGroup", //"simpleGroup",
                fieldArray: {
                  fieldGroupClassName: "row",
                  templateOptions: {
                    btnText: this.translate.instant("ADD"),
                    repeatability: group ? group.repeatability : null
                  },
                  fieldGroup: [grpfield]
                },
                // fieldGroup: [grpfield],
                showTitle: true
              });
            }
          }
        } else {
          if (enabledPageBreaks) {
            pageFields.push(grpfield);
          } else {
            formlyFields.push(grpfield);
          }
        }
      }
      // console.log("PageFields", pageFields);
      // console.log("FormFields", formlyFields);
    });

    if (enabledPageBreaks) {
      return [
        {
          type: "pageGroup", //"repeatGroup",
          fieldArray: {
            fieldGroupClassName: "row",
            templateOptions: {},
            fieldGroup: formlyFields
          },
          fieldGroup: formlyFields,
          showTitle: true
        }
      ];
    } else {
      return formlyFields;
    }

    // console.log(formlyFields);
  }
  detectPagebreaks(fields) {
    let pageBreaks = fields.filter((a) => {
      return a.type == "pagebreak";
    });
    let pagegroups = [];
    if (pageBreaks) {
      // for(let i=0; i <= pageBreaks.length; i++){
      let index = 0;
      // }
      pagegroups = [{ id: "pagebreak0", label: "pagebreak0" }];
      fields.forEach((field) => {
        field.group = "pagegroup";
        if (field.type == "pagebreak") {
          index++;
          pagegroups.push({
            id: "pagebreak" + index,
            label: "pagebreak" + index
          });
        }
        field.groupId = "pagebreak" + index;
      });
    }

    return { fields: fields, groups: pagegroups };
  }
  prepareForm(questionaire, values) {
    let fields = questionaire.fields;
    if (fields.length) {
      if (
        questionaire.fields[questionaire.fields.length - 1].id == "lastBreak"
      ) {
        questionaire.fields.pop();
      }
      fields.forEach((element) => {
        element.value = null;
        if (element.type != "label") {
          if (!element.groupId) {
            if (
              values[element.id] !== undefined &&
              values[element.id] !== null
            ) {
              element.value = this.getElementValue(
                element.type,
                values[element.id],
                element.default,
                element.extra
              );
              if (element.type == "list") {
                element["list_values_are_keys"] = true;
              }
            }
          } else {
            if (values[element.groupId]) {
              values[element.groupId].forEach((group) => {
                const value = this.getElementValue(
                  element.type,
                  group[element.id],
                  element.default,
                  element.extra
                );
                if (element.value === null && value !== undefined) {
                  element.value = [];
                }
                element.value.push(value);
                if (element.type == "list") {
                  element["list_values_are_keys"] = true;
                }
              });
            }
          }
        } else {
          element.value = null;
        }
      });
    }

    return fields;
  }

  getElementValue(
    elementType,
    elementValue,
    elementDefault = null,
    elementExtra = null,
    load = false
  ) {
    let newValue = null;
    if (elementValue) {
      switch (elementType) {
        case "checkbox":
          newValue = elementValue ? 1 : 0;
          break;
        case "time":
          if (load) {
            newValue =
              elementValue.length == 5
                ? moment(elementValue, "HH:mm")
                : elementValue.indexOf(".") !== -1
                ? moment(elementValue, moment.ISO_8601)
                    .toISOString(true)
                    .replace(/\.\d+Z/, "Z")
                : "";
          } else {
            newValue =
              elementValue.length == 5
                ? moment(elementValue, "HH:mm")
                : elementValue.indexOf(".") !== -1
                ? moment(elementValue, moment.ISO_8601)
                    .toISOString(false)
                    .replace(/\.\d+Z/, "Z")
                : "";
          }
          // console.log("SUBMITTING TIME: ", newValue);
          break;
        case "date":
          if (load) {
            newValue = moment(elementValue, "YYYY-MM-DD")
              .set("hours", 9)
              .toISOString(true)
              .replace(/\.\d+Z/, "Z");
          } else {
            newValue = moment(elementValue, "YYYY-MM-DD")
              .set("hours", 9)
              .toISOString(false)
              .replace(/\.\d+Z/, "Z");
          }
          // console.log("SUBMITTING DATE: ", newValue);

          break;
        case "datetime":
          if (load) {
            newValue = moment(elementValue, moment.ISO_8601)
              .toISOString(true)
              .replace(/\.\d+Z/, "Z");
          } else {
            newValue = (
              elementValue.length > 20
                ? moment(elementValue, moment.ISO_8601)
                : moment(elementValue, "YYYY-MM-DD HH:mm")
            )
              .toISOString(false)
              .replace(/\.\d+Z/, "Z");
          }
          // console.log("SUBMITTING DATETIME: ", newValue);

          break;
        case "integer":
        case "float":
          newValue = elementValue ? elementValue : 0;
          break;
        case "phone_num":
          newValue = elementValue ? elementValue.e164Number : 0;
          break;
        case "range":
          if (elementExtra.step < 1) {
            newValue = elementValue
              ? (
                  elementValue * elementExtra.step +
                  (elementExtra.from - elementExtra.step)
                ).toFixed(2)
              : elementExtra.from;
          } else {
            newValue = elementValue ? elementValue : elementExtra.from;
          }
          break;
        case "list":
          if (Array.isArray(elementValue)) {
            if (elementValue.length > 0) {
              newValue = elementValue;
            } else {
              newValue = null;
            }
          } else {
            newValue = elementValue; //.value; //its .name if list_values_are_keys == false
          }
          break;
        case "text":
        case "email":
          newValue = !!elementValue ? elementValue : null;
          break;
        default:
          newValue = elementValue;
          break;
      }
    }

    return newValue;
  }

  loadData(form, fields) {
    let curModel = {};
    let model = {};
    form.groups.forEach((element) => {
      curModel[element.id] = [];
      for (let i = 0; i < element.repeatability; i++) {
        curModel[element.id].push({});
      }
    });
    if (fields) {
      if (form.fields) {
        form.fields.forEach((element) => {
          if (element.groupId) {
            let group =
              form.groups[
                this.utilities.getObjFromId(form.groups, element.groupId)
              ];
            let range =
              group.repeatability !== -1
                ? group.repeatability
                : element.value
                ? element.value.length
                : 1;
            if (!model[element.groupId]) {
              model[element.groupId] = [];
            }
            for (let i = 0; i < range; i++) {
              if (!curModel[element.groupId][i]) {
                curModel[element.groupId].push({});
              }
              if (!model[element.groupId][i]) {
                model[element.groupId].push({});
              }
              if (element.value !== undefined && element.value !== null) {
                if (
                  element.value[i] !== undefined &&
                  element.value[i] !== null
                ) {
                  curModel[element.groupId][i][element.id] =
                    this.getElementValue(
                      element.type,
                      element.value[i],
                      element.defaultValue,
                      element.extra,
                      true
                    );
                  if (element.type == "list") {
                    // if (!element.extra.multiple) {
                    //   curModel[element.groupId][i][element.id] = { value: element.value[i], name: element.extra.values[element.value[i]] };
                    // }
                  }
                }
              }
            }
          } else {
            if (element.value !== undefined && element.value !== null) {
              curModel[element.id] = this.getElementValue(
                element.type,
                element.value,
                element.defaultValue,
                element.extra,
                true
              );
              if (element.type == "list") {
                // if (!element.extra.multiple) {
                //   curModel[element.id] = { value: element.value, name: element.extra.values[element.value] };
                // }
              }
            }
          }
        });
        // // console.log({ curModel: curModel, model: model });
        // // console.log('end of load', form, fields);

        return { curModel: curModel, model: model };
      }
    }
  }

  translateDate(date) {
    // console.log('current date input', date);
    if (date) {
      if (Array.isArray(date)) {
        let dates = [];
        date.forEach((dt) => {
          if (dt) {
            dates.push(moment(dt, moment.ISO_8601).toISOString(true));
          } else {
            dates.push(null);
          }
        });
        return dates;
      } else {
        return moment(date, moment.ISO_8601).toISOString(true);
      }
    }
  }

  translateDateTime(date: string) {
    // console.log('current date input', date);
    if (date) {
      if (Array.isArray(date)) {
        let dates = [];
        date.forEach((dt) => {
          if (dt) {
            // console.log(
            //   "load date: ",
            //   moment(date).tz(this.timezone).toISOString(true)
            // );
            dates.push(moment(date).tz(this.timezone).toISOString(true));
          } else {
            dates.push(null);
          }
        });
        return dates;
      } else {
        // console.log(
        //   "load date: ",
        //   moment(date).tz(this.timezone).toISOString(true)
        // );
        return moment(date).tz(this.timezone).toISOString(true);
      }
    }
  }

  async handleErrors(err, form?: FormGroup): Promise<FormGroup> {
    let errors = "";
    if (err.error) {
      console.log("err.error", err.error);
      if (err.error.error === "Unauthenticated.") {
        errors = this.translate.instant("LOGIN.LOGIN_FIRST");
      } else {
        if (err.error.message) {
          errors = err.error.message;
        } else if (
          typeof err.error.error.message === "string" ||
          err.error.error.message instanceof String
        ) {
          errors = err.error.error.message;
        } else {
          Object.keys(err.error.error.message).forEach((key) => {
            let index = key.split("_")[0];
            if (err.error.error.message[key]) {
              Object.keys(err.error.error.message[key]).forEach((k) => {
                errors += err.error.error.message[key][k] + "\n";
                if (form)
                  if (form.controls[index]) {
                    const errJson = {};
                    errJson[k] = err.error.error.message[key][k];
                    form.controls[index].setErrors(errJson);
                    form.controls[index].markAsTouched();
                  }
              });
            }
          });
        }
      }
    } else {
      return null;
    }

    const alert = await this.toastCtrl.create({
      message: errors,
      buttons: [{ cssClass: "white-button-text", text: "OK" }],
      duration: 15000,
      position: "top"
    });
    await alert.present();
    // console.log("printing form", form);
    return form;
  }
}

// let message = "";
// if (res.error)
//   if (res.error.error) {
//     Object.keys(res.error.error).forEach((key) => {
//       message += res.error.error[key] + "\n";
//     });
//   }
// const alert = await this.alertController.create({
//   header: this.translate.instant("LOGIN.FAILED"),
//   message: message,
//   buttons: ["OK"]
// });
// await alert.present();
