import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideHeaderDirective } from './directives/hide-header.directive';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [HideHeaderDirective],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
  ],
  exports: [
    HideHeaderDirective
  ]
})
export class HtCommonModule { }
