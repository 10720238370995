import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { PagePhoto } from "../../models/page-photo";

@Component({
  selector: "app-photo-gallery",
  templateUrl: "./photo-gallery.page.html",
  styleUrls: ["./photo-gallery.page.scss"]
})
export class PhotoGalleryPage implements OnInit {
  @Input() photos: PagePhoto[];
  @Input() index: number = 0;
  @ViewChild("pgSlider", { static: false })
  pgSlider: IonSlides;
  didInit: boolean = false;
  fullscreen = false;
  pgOpts = {
    zoom: true,
    centeredSlides: false,
    autoHeight: false,
    spaceBetween: 2,
    passiveListeners: true
  };
  activeCaption: string;
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    // console.log(this.photos);
    // this.pgSlider.slider
    // console.log(this.pgSlider);

    setTimeout(() => {
      // console.log(this.pgSlider);
    }, 3000);

    if (this.pgSlider) {
      this.pgSlider.update();
      // console.log(this.pgSlider);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.didInit = true;
    }, 200);
  }

  onSlidesDidLoad() {
    this.pgSlider.slideTo(this.index, 0);
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  onSlideTap() {
    // console.log("slide tapped");
    this.fullscreen = !this.fullscreen;
  }

  onSlideDidChange() {
    this.pgSlider.getActiveIndex().then((indx) => {
      this.activeCaption = this.photos[indx].caption;
    });
  }
}
