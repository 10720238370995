import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment-timezone";
import { PhotoGalleryPage } from "../../pages/photo-gallery/photo-gallery.page";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-form-inspect",
  templateUrl: "./form-inspect.component.html",
  styleUrls: ["./form-inspect.component.scss"]
})
export class FormInspectComponent implements OnInit {
  @Input() fields;
  @Input() model;
  lang: string;
  timezone = environment.timezone;
  constructor(
    private modalController: ModalController,
    private translateService: TranslateService
  ) {}
  ngOnInit() {
    this.lang = this.translateService.getDefaultLang();
  }
  formatDate(date) {
    return moment(date).toDate();
  }

  async previewPhoto(gallery, index) {
    // console.log(page.reservationForm);

    const modal = await this.modalController.create({
      component: PhotoGalleryPage,
      cssClass: "photo-gallery-page",
      componentProps: {
        photos: gallery,
        index: index
      }
    });
    return await modal.present();
  }
}
