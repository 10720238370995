import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Device } from "@capacitor/device";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import "@capacitor-community/firebase-analytics";
export enum EVENTTYPES {
  OPEN_PAGE = "open_page",
  OPEN_ACTIVITY = "open_activity",
  OPEN_ACTIVITY_CALENDAR = "open_activity_calendar",
  OPEN_PROMOTION = "open_promotion",
  OPEN_QUESTIONNAIRE = "open_questionnaire",
  OPEN_QUESTIONNAIRE_LIST = "open_questionnaire_list",
  OPEN_FAST_CHECKIN = "open_fast_checkin",
  SKIP_FAST_CHECKIN = "skip_fast_checkin",
  OPEN_HOTEL_MAP = "open_hotel_map",
  OPEN_POI_MAP = "open_poi_map",
  OPEN_NPS = "open_nps",
  OPEN_HOTEL_LIST = "open_hotel_list",
  OPEN_PROFILE = "open_profile",
  OPEN_REQUEST_LIST = "open_request_list",
  OPEN_SMART_ROOM = "open_smart_room",

  OPEN_RESERVATION = "open_reservation",
  OPEN_ORDER = "open_order",
  OPEN_SERVICE = "open_service",
  OPEN_MENU = "open_menu",
  OPEN_LOGIN = "open_login",
  OPEN_HOTEL_BOOKING = "open_hotel_booking",
  OPEN_EXTERNAL = "open_external",

  OPEN_RESERVATION_HISTORY = "open_reservation_history",
  OPEN_ACTIVITY_RESERVATION_HISTORY = "open_activity_reservation_history",
  OPEN_ORDER_HISTORY = "open_order_history",
  OPEN_SERVICE_HISTORY = "open_service_history",

  SUBMIT_ORDER = "submit_order",
  SUBMIT_RESERVATION = "submit_reservation",
  SUBMIT_QUESTIONNAIRE = "submit_questionnaire",
  SUBMIT_SERVICE = "submit_service",
  SUBMIT_NPS = "submit_nps",

  LOGIN_SUCCESS = "login_success",
  LOGOUT = "logout",

  SELECT_TAB = "select_tab",
  SELECT_HOTEL = "select_hotel"
}
export enum EVENTORIGIN {
  DASHBOARD = "dashboard",
  HOTEL_TAB = "hotel_tab",
  SERVICE_HUB = "service_hub",
  HELP_TAB = "help_tab",
  ACTIVITY_CALENDAR = "activity_calendar",
  ACTIVITY_LIST = "activity_list",
  PROMOTION = "promotion",
  HOTEL_LIST = "hotel_list",
  INTRODUCTION = "introduction",
  LOGIN_CONFIRMATION = "login_confirmation",
  PROFILE = "profile",
  URL_LINK = "url_link",
  PAGE_VIEW = "page_view",
  ACTIVITY_DETAILS = "activity_details",
  NOTIFICATIONS_LIST = "notifications_list",
  RESERVATION_PAGE = "reservation_page"
}
@Injectable({
  providedIn: "root"
})
export class AnalyticsService {
  analyticsEnabled = true;

  constructor(private router: Router) {}

  async initFb() {
    if ((await Device.getInfo()).platform == "web") {
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig).then(
        () => {
          FirebaseAnalytics.setCollectionEnabled({
            enabled: this.analyticsEnabled
          });
          // if (this.hotelService.hotel.id) {
          //   FirebaseAnalytics.setUserProperty({
          //     name: "hotelId",
          //     value: this.hotelService.hotel.id
          //   });
          // }
          this.router.events
            .pipe(filter((e: RouterEvent) => e instanceof NavigationEnd))
            .subscribe((e: RouterEvent) => {
              // console.log("route changed: ", e.url);
              // if (this.hotelService.hotel.id) {
              //   FirebaseAnalytics.setUserProperty({
              //     name: "hotelId",
              //     value: this.hotelService.hotel.id
              //   });
              // }

              this.setScreenName(e.url);
              const isPageWithArgs = e.url.indexOf("/") !== -1;
              const pageType = isPageWithArgs ? e.url : e.url.split("/")[0];
              const data = {
                // hotelId: this.hotelService.hotel.id,
                id: ""
              };
              if (isPageWithArgs) {
                data.id = e.url.split("/").reverse()[0];
              }
              this.logEvent(pageType, data);
            });
        }
      );
    }
  }

  setUser(userId) {
    // Use Firebase Auth uid
    // const enc = CryptoJS.AES.encrypt(userId, environment.encKey);
    // FirebaseAnalytics.setUserId({
    //   userId: enc
    // });
  }

  setHotel(value) {
    this.setProperty("hotelId", value);
  }

  setProperty(name, value) {
    FirebaseAnalytics.setUserProperty({
      name: name,
      value: value
    });
  }

  logEvent(name, data) {
    FirebaseAnalytics.logEvent({
      name: name,
      params: data
    });
  }

  setScreenName(screenName) {
    FirebaseAnalytics.setScreenName({
      screenName: screenName,
      nameOverride: screenName
    });
  }

  toggleAnalytics() {
    this.analyticsEnabled = !this.analyticsEnabled;
    FirebaseAnalytics.setCollectionEnabled({
      enabled: this.analyticsEnabled
    });
  }

  enable() {
    return FirebaseAnalytics.enable();
  }

  disable() {
    return FirebaseAnalytics.disable();
  }
}
