import { isDevMode, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { HotelPagesService } from "./ht-client-core/pagesModule/services/hotel-pages.service";
import { ApiService } from "./ht-client-core/common/services/api/api.service";
import { IonicStorageModule } from "@ionic/storage";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { CacheModule } from "ionic-cache";
import { HtCommonModule } from "./ht-client-core/common/ht-common.module";
import { HotelService } from "./ht-client-core/common/services/hotel/hotel.service";
import { MenuService } from "./ht-client-core/common/services/menu/menu.service";
import { AuthenticationService } from "./ht-client-core/common/services/authentication/authentication.service";
import { HtClientComponentsModule } from "./ht-client-core/common/components/components.module";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsProvider } from "./ht-client-core/common/services/forms/forms";
import { RatingService } from "./ht-client-core/common/services/rating/rating";
import { IonicSelectableModule } from "ionic-selectable";
import { FormlyIonicModule } from "@ngx-formly/ionic";
import { FormlyModule } from "@ngx-formly/core";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import {
  FontAwesomeModule,
  FaIconLibrary
} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { PhotoServiceProvider } from "./ht-client-core/common/services/photo-service/photo-service";
import { WeatherService } from "./ht-client-core/common/services/weather/weather.service";
import { UtilsService } from "./ht-client-core/common/services/utils/utils.service";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";
import { NotificationService } from "./ht-client-core/notificationsModule/services/notification/notification.service";
import { LanguageService } from "./ht-client-core/common/services/language/language.service";
import { PhotoGalleryPageModule } from "./ht-client-core/common/pages/photo-gallery/photo-gallery.module";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SwiperModule } from "swiper/angular";
import { LowerCasePipe } from "@angular/common";
import { RoomPanelPageModule } from "./ht-client-core/knxModule/pages/room-panel/room-panel.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "src/environments/environment";

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" },
    { prefix: "./assets/core-i18n/", suffix: ".json" }
  ]);
}
// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    CacheModule.forRoot(),
    HtCommonModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyIonicModule,
    IonicSelectableModule,
    LeafletModule,
    HtClientComponentsModule,
    FontAwesomeModule,
    PhotoGalleryPageModule,
    LottieModule.forRoot({ player: playerFactory }),
    // ServiceWorkerModule.register("ngsw-worker.js", {
    //   enabled: environment.production
    // }),

    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    SwiperModule,
    RoomPanelPageModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      // enabled: !isDevMode(),
      enabled: environment.production
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: "registerWhenStable:30000"
    })
  ],
  providers: [
    SocialSharing,
    Storage,
    HotelPagesService,
    ApiService,
    WeatherService,
    HotelService,
    MenuService,
    AuthenticationService,
    UtilsService,
    PhotoServiceProvider,
    // ImagePicker,
    FormsProvider,
    RatingService,
    NotificationService,
    LanguageService,
    LowerCasePipe,

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
