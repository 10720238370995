import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "empty-list-component",
  templateUrl: "./empty-list-component.component.html",
  styleUrls: ["./empty-list-component.component.scss"]
})
export class EmptyListComponent {}
