import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-custom-range-input',
  templateUrl: './custom-range-input.component.html',
  styleUrls: ['./custom-range-input.component.scss'],
})
export class CustomRangeInputComponent extends FieldType implements OnInit {

  ngOnInit() { }

  currentValue(model, step, min) {
    if (model) {
      return step < 1 ? (model * step + (min - step)).toFixed(2) : model;
    } else {
      return this.field.defaultValue;
    }
  }
}
