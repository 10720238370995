import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/ht-client-core/common/services/api/api.service";
import { AuthenticationService } from "src/app/ht-client-core/common/services/authentication/authentication.service";
import { Service } from "../../common/models/service";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root"
})
export class ServicesService {
  timezone = environment.timezone;
  constructor(
    private api: ApiService,
    private authService: AuthenticationService
  ) {}

  getAll(): Observable<Service[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/services",
        {
          "filters[forGuests]": true
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getById(id): Observable<Service> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/services/" + id,
        {},
        null,
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  submit(service_id, input) {
    return this.api.post(
      "hotels/" +
        this.authService.getHotelId() +
        "/services/" +
        service_id +
        "/input",
      {
        input: input
      },
      {
        headers: {
          Authorization: "Bearer " + this.authService.getToken()
        }
      }
    );
  }

  submitUnregistered(service_id, input) {
    return this.api.post(
      "hotels/" +
        this.authService.getHotelId() +
        "/services/" +
        service_id +
        "/input/unregistered",
      {
        input: input
      }
    );
  }

  getHistory(service_id) {
    return this.api
      .get(
        "hotels/" +
          this.authService.getHotelId() +
          "/services/" +
          service_id +
          "/input",
        null,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        }
      )
      .pipe(
        map((data, key) => {
          let res = data["data"];

          return { data: res };
        })
      );
  }

  getAllHistory(page: string = "1") {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/serviceInput",
        {
          include: "service",
          page: page
        },
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken()
          }
        }
      )
      .pipe(
        map((data, key) => {
          let res = data["data"];

          return { data: res };
        })
      );
  }
}
