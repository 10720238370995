import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomDateInputComponent } from "./custom-date-input/custom-date-input.component";
import { CustomGeneralInputComponent } from "./custom-general-input/custom-general-input.component";
import { CustomTextAreaComponent } from "./custom-textarea/custom-textarea.component";
import { CustomLabelComponent } from "./custom-label/custom-label.component";
import { CustomPhotoGalleryComponent } from "./custom-photo-gallery/custom-photo-gallery.component";
import { CustomRadioComponent } from "./custom-radio/custom-radio.component";
import { CustomRangeInputComponent } from "./custom-range-input/custom-range-input.component";
import { CustomRatingInputComponent } from "./custom-rating-input/custom-rating-input.component";
import { CustomSelectComponent } from "./custom-select/custom-select.component";
import { CustomCheckboxComponent } from "./custom-checkbox/custom-checkbox.component";
import { RepeatGroupComponent } from "./repeat-group/repeat-group.component";
import { PageGroupComponent } from "./page-group/page-group.component";
import { ContactListComponent } from "./contact-list/contact-list.component";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { TranslateModule } from "@ngx-translate/core";
import { IonicSelectableModule } from "ionic-selectable";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LanguagePopoverComponent } from "./language-popover/language-popover.component";
import { TooltipMessageComponent } from "./tooltip-message/tooltip-message.component";
import { CustomRadioInlineComponent } from "./custom-radio-inline/custom-radio-inline.component";
import { FormInspectComponent } from "./form-inspect/form-inspect.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NpsConfirmationComponent } from "./nps-confirmation/nps-confirmation.component";
import { NpsFollowUpComponent } from "./nps-followup/nps-followup.component";
import { HTPipesModule } from "../pipes/pipes.module";
import { SimpleGroupComponent } from "./simple-group/simple-group.component";
import { CustomPhoneNumComponent } from "./custom-phone-num/custom-phone-num.component";

import { SlideToConfirmComponent } from "./slide-to-confirm/slide-to-confirm.component";
import { ReservationHistorySelectorComponent } from "./reservation-history-selector/reservation-history-selector.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { EmptyListComponent } from "./empty-list-component/empty-list-component.component";
import { ChooseAppPopoverComponent } from "./choose-app-popover/choose-app-popover.component";

@NgModule({
  declarations: [
    CustomDateInputComponent,
    CustomGeneralInputComponent,
    CustomTextAreaComponent,
    CustomLabelComponent,
    CustomPhotoGalleryComponent,
    CustomRadioComponent,
    CustomRangeInputComponent,
    CustomRatingInputComponent,
    CustomSelectComponent,
    RepeatGroupComponent,
    CustomCheckboxComponent,
    LanguagePopoverComponent,
    TooltipMessageComponent,
    CustomRatingInputComponent,
    CustomRadioInlineComponent,
    FormInspectComponent,
    NpsConfirmationComponent,
    NpsFollowUpComponent,
    ContactListComponent,
    PageGroupComponent,
    SimpleGroupComponent,
    CustomPhoneNumComponent,
    SlideToConfirmComponent,
    ReservationHistorySelectorComponent,
    EmptyListComponent,
    ChooseAppPopoverComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormlyModule.forRoot({
      types: getFormlyTypes(),
      validationMessages: [
        {
          name: "required",
          message: RequiredValidatorMessage
        },
        { name: "min", message: minValidationMessage },
        { name: "max", message: maxValidationMessage }
      ]
    }),
    IonicSelectableModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    FontAwesomeModule,
    HTPipesModule,
    NgxIntlTelInputModule
  ],
  exports: [
    CustomDateInputComponent,
    CustomGeneralInputComponent,
    CustomTextAreaComponent,
    CustomLabelComponent,
    CustomPhotoGalleryComponent,
    CustomRadioComponent,
    CustomRangeInputComponent,
    CustomRatingInputComponent,
    CustomSelectComponent,
    RepeatGroupComponent,
    CustomCheckboxComponent,
    LanguagePopoverComponent,
    TooltipMessageComponent,
    CustomRatingInputComponent,
    CustomRadioInlineComponent,
    FormInspectComponent,
    NpsConfirmationComponent,
    NpsFollowUpComponent,
    ContactListComponent,
    PageGroupComponent,
    SimpleGroupComponent,
    CustomPhoneNumComponent,
    SlideToConfirmComponent,
    ReservationHistorySelectorComponent,
    EmptyListComponent,
    ChooseAppPopoverComponent
  ]
})
export class HtClientComponentsModule {}
export function RequiredValidatorMessage(err, field: FormlyFieldConfig) {
  return field.templateOptions.requiredError;
}
export function minValidationMessage(err, field) {
  return `This value should be more than ${field.templateOptions.min}`;
}

export function maxValidationMessage(err, field) {
  return `This value should be less than ${field.templateOptions.max}`;
}
export function getFormlyTypes() {
  return [
    {
      name: "date",
      component: CustomDateInputComponent
    },
    {
      name: "rating",
      component: CustomRatingInputComponent
    },
    {
      name: "general-input",
      component: CustomGeneralInputComponent
    },
    {
      name: "customSelect",
      component: CustomSelectComponent
    },
    {
      name: "repeatGroup",
      component: RepeatGroupComponent
    },
    {
      name: "customRadio",
      component: CustomRadioComponent
    },
    {
      name: "photoGallery",
      component: CustomPhotoGalleryComponent
    },
    {
      name: "customLabel",
      component: CustomLabelComponent
    },
    {
      name: "customRange",
      component: CustomRangeInputComponent
    },
    {
      name: "customTextarea",
      component: CustomTextAreaComponent
    },
    {
      name: "customCheckbox",
      component: CustomCheckboxComponent
    },
    {
      name: "customRadioInline",
      component: CustomRadioInlineComponent
    },
    {
      name: "pageGroup",
      component: PageGroupComponent
    },
    {
      name: "simpleGroup",
      component: SimpleGroupComponent
    },
    {
      name: "customPhoneNum",
      component: CustomPhoneNumComponent
    }
  ];
}
