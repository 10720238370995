import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { AuthenticationService } from "../authentication/authentication.service";
import { Promotion } from "../../models/promotion";
import { PopoverController } from "@ionic/angular";
import { PromotionDialogPage } from "../../pages/promotion-dialog/promotion-dialog.page";

@Injectable({
  providedIn: "root"
})
export class PromotionService {
  constructor(
    private api: ApiService,
    private authService: AuthenticationService,
    private popoverController: PopoverController
  ) {}

  getAll(): Observable<Promotion[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/offers",
        {
          include: "offerable"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getById(id): Observable<Promotion> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/offers/" + id,
        {
          include: "offerable"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getOffersForServiceHub(): Observable<Promotion[]> {
    return this.getAll().pipe(
      map((res) => {
        return res.filter(
          (offer) =>
            offer.guest_app_sections.indexOf("services") !== -1 ||
            offer.guest_app_sections.indexOf("reservations") !== -1 ||
            offer.guest_app_sections.indexOf("orders") !== -1
        );
      })
    );
  }

  getOffersForOrders(): Observable<Promotion[]> {
    return this.getAll().pipe(
      map((res) => {
        return res.filter((offer) =>
          offer.guest_app_sections.indexOf("orders")
        );
      })
    );
  }

  getOffersForReservations(): Observable<Promotion[]> {
    return this.getAll().pipe(
      map((res) => {
        return res.filter((offer) =>
          offer.guest_app_sections.indexOf("reservations")
        );
      })
    );
  }

  getOffersForServices(): Observable<Promotion[]> {
    return this.getAll().pipe(
      map((res) => {
        return res.filter((offer) =>
          offer.guest_app_sections.indexOf("services")
        );
      })
    );
  }
}
