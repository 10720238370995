import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  KNXSPECTYPES,
  SmartControlService
} from "../../services/baos/smart-control.service";
import { map } from "rxjs/operators";
import { UtilsService } from "src/app/ht-client-core/common/services/utils/utils.service";
import { AuthenticationService } from "src/app/ht-client-core/common/services/authentication/authentication.service";
import { Booking } from "src/app/ht-client-core/common/models/booking";
import { TranslateService } from "@ngx-translate/core";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { ModalController } from "@ionic/angular";
import { Subscription, timer } from "rxjs";

@Component({
  selector: "app-room-panel",
  templateUrl: "./room-panel.page.html",
  styleUrls: ["./room-panel.page.scss"]
})
export class RoomPanelPage implements OnInit, OnDestroy {
  room;
  controls;
  dataPoints;
  user;
  booking: Booking;
  controlTypesDictionary = {};
  turnedOn = false;
  roomSpecs;
  lang;
  tab: string = "lights";
  userTemp = 24;
  // fanLvls = [16, 25, 67, 100];
  // fanLvls = [42, 25, 67, 100];
  fanLvls = [42, 85, 170, 255];
  currentFanlvl;
  mmr;
  scenes = [
    {
      code: 1,
      description: "Bright",
      icon: "assets/imgs/icons-knx-scene-bright.png"
    },
    {
      code: 2,
      description: "Relax",
      icon: "assets/imgs/icons-knx-scene-relax.png"
    },
    {
      code: 3,
      description: "Night",
      icon: "assets/imgs/icons-knx-scene-night.png"
    }
  ];
  shadeLvl = 0;
  checkRoomStatus: Subscription;
  //relax
  //night
  constructor(
    private smartCtrlService: SmartControlService,
    private utilService: UtilsService,
    private authService: AuthenticationService,
    private translateService: TranslateService,
    public modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.lang = this.translateService.getDefaultLang();
    this.user = this.authService.getUser();
    console.log(this.user);
    if (this.user) {
      this.authService.getBooking().then((booking) => {
        console.log(booking);
        this.booking = booking;
        if (this.booking.room) {
          this.smartCtrlService
            .getSpecs(this.booking.room.id, KNXSPECTYPES.ROOM)
            .subscribe((res) => {
              console.log("Room specs", res);
              this.roomSpecs = res;
              this.checkRoomStatus = timer(0, 10000)
                .pipe(
                  map(() => {
                    this.refreshRoom();
                  })
                )
                .subscribe();
            });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.checkRoomStatus.unsubscribe();
  }

  refreshRoom() {
    if (this.roomSpecs.clima) {
      this.checkStatus(this.roomSpecs.clima.ACT);
      this.checkStatus(this.roomSpecs.clima.ACF);
      this.checkStatus(this.roomSpecs.clima.ACP);
    }
    if (this.roomSpecs.lights) {
      this.roomSpecs.lights.forEach((light) => {
        this.checkStatus(light);
      });
    }
    if (this.roomSpecs.shades) {
      this.roomSpecs.shades.forEach((shade) => {
        this.checkStatus(shade);
      });
    }
  }

  switchOnOff(device) {
    console.log("device", device);
    Haptics.impact({ style: ImpactStyle.Light });

    if (
      device.function == "L" ||
      device?.function === "SH" ||
      device?.function === "D" ||
      device?.function === "DS" ||
      device?.function === "DIN" ||
      device?.function === "ACP"
    ) {
      if (!device.loading) {
        const inputDP = device.datapoints.filter((a) => {
          return a.flag === "write" && a.type === "on/off";
        })[0];
        const outputDP = device.datapoints.filter((a) => {
          return (
            a.flag === "read" &&
            (a.type === "on/off status" || a.type === "value status")
          );
        })[0];
        if (inputDP) {
          this.smartCtrlService
            .setDataPoint(
              this.booking.room.id,
              KNXSPECTYPES.ROOM,
              inputDP.dp_id,
              {
                command: 3,
                value: !device.value
              }
            )
            .subscribe((res) => {
              // console.log("res", res);
              this.smartCtrlService
                .getDataPoint(
                  this.booking.room.id,
                  KNXSPECTYPES.ROOM,
                  outputDP.dp_id
                )
                .subscribe((status) => {
                  console.log("status", status);
                  this.checkStatus(device);
                });
            });
        }
      }
    }
  }

  changeValue(device, value, index) {
    if (!device.loading) {
      const inputDP = device.datapoints.filter((a) => {
        return (
          a.flag === "write" && (a.type === "relative" || a.type === "value")
        );
      })[0];
      const outputDP = device.datapoints.filter((a) => {
        return a.flag === "read" && a.type === "value status";
      })[0];
      console.log("read", inputDP.dp_id, "write", outputDP.dp_id);
      if (inputDP) {
        this.smartCtrlService
          .setDataPoint(
            this.booking.room.id,
            KNXSPECTYPES.ROOM,
            inputDP.dp_id,
            {
              command: 3,
              value: value
            }
          )
          .subscribe((res) => {
            // console.log("res", res);
            if (device.timeout) {
              setTimeout(() => {
                this.checkStatus(device);
              }, device.timeout);
            } else {
              this.checkStatus(device);
            }
          });
      }
    }
  }

  startRoll(device, value) {
    if (!device.loading) {
      const inputDP = device.datapoints.filter((a) => {
        return a.flag === "write" && a.type === "down/up";
      })[0];
      const outputDP = device.datapoints.filter((a) => {
        return a.flag === "read" && a.type === "value status";
      })[0];
      if (inputDP) {
        this.smartCtrlService
          .setDataPoint(
            this.booking.room.id,
            KNXSPECTYPES.ROOM,
            inputDP.dp_id,
            {
              command: 3,
              value: value
            }
          )
          .subscribe((res) => {
            // console.log("res", res);
            if (device.watcher !== null) {
              clearInterval(device.watcher);
            }
            device.watcher = setInterval(() => {
              this.checkStatus(device);
              if (device.value === 0 || device.value === 255) {
                clearInterval(device.watcher);
              }
            }, 2000);
          });
      }
    }
  }

  stopRoll(device) {
    if (!device.loading) {
      const inputDP = device.datapoints.filter((a) => {
        return a.flag === "write" && a.type === "stop";
      })[0];
      const outputDP = device.datapoints.filter((a) => {
        return a.flag === "read" && a.type === "value status";
      })[0];
      if (inputDP) {
        this.smartCtrlService
          .setDataPoint(
            this.booking.room.id,
            KNXSPECTYPES.ROOM,
            inputDP.dp_id,
            {
              command: 3,
              value: true
            }
          )
          .subscribe((res) => {
            // console.log("res", res);
            clearInterval(device.watcher);
            this.checkStatus(device);
          });
      }
    }
  }

  changeRelativeValue(device, index) {
    if (!device.loading) {
      const inputDP = device.datapoints.filter((a) => {
        return a.flag === "write" && a.type === "relative";
      })[0];
      const outputDP = device.datapoints.filter((a) => {
        return a.flag === "read" && a.type === "value status";
      })[0];
      console.log("read", inputDP.dp_id, "write", outputDP.dp_id);
      if (inputDP) {
        this.smartCtrlService
          .setDataPoint(
            this.booking.room.id,
            KNXSPECTYPES.ROOM,
            inputDP.dp_id,
            {
              command: 3,
              value: {
                Control: index > 0,
                StepCode: 3
              }
            }
          )
          .subscribe((res) => {
            // console.log("res", res);
            this.checkStatus(device);
          });
      }
    }
  }

  changeFanValue(device, index) {
    if (index > 0) {
      if (this.currentFanlvl < 3) {
        this.currentFanlvl++;
      }
    } else {
      if (this.currentFanlvl > 0) {
        this.currentFanlvl--;
      }
    }

    if (!device.loading) {
      const inputDP = device.datapoints.filter((a) => {
        return a.flag === "write" && a.type === "relative";
      })[0];
      const outputDP = device.datapoints.filter((a) => {
        return a.flag === "read" && a.type === "value status";
      })[0];
      console.log("read", inputDP.dp_id, "write", outputDP.dp_id);
      if (inputDP) {
        this.smartCtrlService
          .setDataPoint(
            this.booking.room.id,
            KNXSPECTYPES.ROOM,
            inputDP.dp_id,
            {
              command: 3,
              value: this.fanLvls[this.currentFanlvl]
            }
          )
          .subscribe((res) => {
            // console.log("res", res);
            if (device.timeout) {
              setTimeout(() => {
                this.checkStatus(device);
              }, 3000);
            } else {
              this.checkStatus(device);
            }
          });
      }
    }
  }

  changeScene(device, index) {
    //
    if (!device.loading) {
      const inputDP = device.datapoints.filter((a) => {
        return a.flag === "write" && a.type === "value";
      })[0];
      if (inputDP) {
        this.smartCtrlService
          .setDataPoint(
            this.booking.room.id,
            KNXSPECTYPES.ROOM,
            inputDP.dp_id,
            {
              command: 3,
              value: { Control: false, Scene: index }
            }
          )
          .subscribe((res) => {
            setTimeout(() => {
              // console.log("res", res);
              this.refreshRoom();
            }, 2000);
          });
      }
    }
  }

  setTemperature(device) {
    // Control: index === 1 ? true : false,
    // Value: 19
  }

  switchOnOnly(device) {
    console.log("device", device);
    Haptics.impact({ style: ImpactStyle.Light });

    if (!device.loading) {
      const inputDP = device.datapoints.filter((a) => {
        return a.flag === "write" && a.type === "on/off";
      })[0];
      if (inputDP) {
        this.smartCtrlService
          .setDataPoint(
            this.booking.room.id,
            KNXSPECTYPES.ROOM,
            inputDP.dp_id,
            {
              command: 3,
              value: device.hardValue ? device.setValue : true
            }
          )
          .subscribe(
            (res) => {
              device.value = true;
              if (device.timeout) {
                setTimeout(() => {
                  console.log("1 sec passed, reseting", device.value);
                  device.value = false;
                  console.log("device value", device.value);
                  Haptics.impact({ style: ImpactStyle.Light });
                  if (device.function === "C/F/L") {
                    this.refreshRoom();
                  }
                }, device.timeout);
              }
            },
            (err) => {
              device.value = true;

              if (device.timeout) {
                setTimeout(() => {
                  console.log("1 sec passed, reseting", device.value);
                  device.value = false;
                  console.log("device value", device.value);
                  Haptics.impact({ style: ImpactStyle.Light });
                }, device.timeout);
              }
            }
          );
      }
    }
  }

  async checkStatus(device) {
    const outputDP = device.datapoints.filter((a) => {
      return (
        a.flag === "read" &&
        (a.type === "on/off status" || a.type === "value status")
      );
    })[0];
    if (device.function === "DIN") {
      await setTimeout(() => {}, 3000);
    }
    if (outputDP) {
      this.smartCtrlService
        .getDataPoint(this.booking.room.id, KNXSPECTYPES.ROOM, outputDP.dp_id)
        .subscribe((status) => {
          device.value = status.value;
          if (device.function === "ACT") {
            this.userTemp = status.value;
          }
          if (device.function === "ACF") {
            this.currentFanlvl = this.fanLvls.indexOf(status.value);
          }
          if (device.function === "CW" || device.function === "CB") {
            device.percentage = (device.value / 255) * 100;
          }
          console.log("status", status);
          Haptics.impact({ style: ImpactStyle.Medium });
        });
    }
  }

  // checkRoomStatus() {
  //   // this.smartCtrlService
  //   //   .getDataPointValues(this.booking.room.id, KNXSPECTYPES.ROOM)
  //   //   .subscribe((status) => {
  //   //     console.log("values", status);
  //   //   });
  // }
}
