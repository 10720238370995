import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild
} from "@angular/core";
import {
  Animation,
  Gesture,
  GestureController,
  GestureDetail,
  createAnimation
} from "@ionic/angular";
import { Haptics, NotificationType } from "@capacitor/haptics";

@Component({
  selector: "app-slide-to-confirm",
  templateUrl: "./slide-to-confirm.html",
  styleUrls: ["./slide-to-confirm.component.scss"]
})
export class SlideToConfirmComponent {
  @Input() confirmed: boolean = false;
  @Input() disabled: boolean = false;
  @Output() confirmedChange = new EventEmitter<boolean>();
  @Output() resetRequest = new EventEmitter<boolean>();

  @ViewChild("confirmationslider") p: ElementRef;
  @ViewChild("slidercontainer") container: ElementRef;
  sliderPosition: number = 0;
  initialX: number = 0;
  gesture: Gesture | undefined;
  maxSlideWidth: number = 0;
  started = false;
  animation: Animation;
  constructor(private gestureCtrl: GestureController) {}

  ngOnInit() {
    setTimeout(() => {
      this.maxSlideWidth = this.container.nativeElement.clientWidth - 44;
      this.animation = createAnimation()
        .addElement(this.p.nativeElement)
        .duration(1000)
        .fromTo(
          "transform",
          "translateX(0)",
          `translateX(${this.maxSlideWidth}px)`
        );
      this.gesture = this.gestureCtrl.create(
        {
          gestureName: "pan",
          el: this.p.nativeElement,
          threshold: 0,
          onMove: (event: GestureDetail) => this.onPanStart(event),
          onEnd: (event: GestureDetail) => this.onPanEnd(event)
        },
        true
      );
      this.gesture.enable();
    }, 1000);
  }

  ngOnChanges() {
    if (this.confirmed === false) {
      this.reset();
    }
  }

  ngOnDestroy() {
    if (this.gesture) {
      this.gesture.destroy();
    }
  }

  onPanStart(event: GestureDetail) {
    if (!this.started && !this.disabled) {
      this.animation.progressStart();
      this.started = true;
    }

    this.animation.progressStep(this.getStep(event));
  }

  onPanEnd(event: GestureDetail) {
    if (!this.started || this.disabled) {
      return;
    }

    this.gesture.enable(false);

    const step = this.getStep(event);
    const shouldComplete = step > 0.8;
    if (this.animation)
      this.animation.progressEnd(shouldComplete ? 1 : 0, step).onFinish(() => {
        this.gesture.enable(true);
      });

    this.initialX = shouldComplete ? this.maxSlideWidth : 0;
    if (shouldComplete) {
      this.confirm();
    }
    this.started = false;
  }

  getStep(ev) {
    const delta = this.initialX + ev.deltaX;
    return this.clamp(0, delta / this.maxSlideWidth, 1);
  }

  clamp(min, n, max) {
    return Math.max(min, Math.min(n, max));
  }

  confirm() {
    setTimeout(() => {
      this.confirmed = true;
      this.gesture.enable(false);
      this.confirmedChange.emit(this.confirmed);
      Haptics.notification({ type: NotificationType.Success });
    }, 200);
  }

  public reset() {
    setTimeout(() => {
      this.animation.progressEnd(0, 1, 200).onFinish(() => {
        this.gesture.enable(true);
        this.initialX = 0;
      });
    }, 200);
  }
}
