import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GreekReplaceAccentedPipe } from "./greekReplaceAccented/greekReplaceAccented";
import { MapSpacesPipe } from "./map-spaces/map-spaces.pipe";
import { FilterSelectedPipe } from "./filter-selected/filter-selected.pipe";
import { SearchFilterPipe } from "./search-filter/search-filter.pipe";
import { FilterByEvCatPipe } from "./filter-by-event-category/filter-by-event-category.pipe";
import { MomentPipe } from "./date-format/date-format.pipe";

@NgModule({
  declarations: [
    GreekReplaceAccentedPipe,
    MapSpacesPipe,
    FilterSelectedPipe,
    SearchFilterPipe,
    FilterByEvCatPipe,
    MomentPipe
  ],
  imports: [CommonModule],
  exports: [
    GreekReplaceAccentedPipe,
    MapSpacesPipe,
    FilterSelectedPipe,
    SearchFilterPipe,
    FilterByEvCatPipe,
    MomentPipe
  ]
})
export class HTPipesModule {
  static forRoot() {
    return {
      ngModule: HTPipesModule,
      providers: []
    };
  }
}
