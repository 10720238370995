import { Pipe, PipeTransform } from "@angular/core";
import { MenuItemExtraOption } from "../../models/menu-item-extra-option";

@Pipe({
  name: "filterSelected",
})
export class FilterSelectedPipe implements PipeTransform {
  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
  transform(value: MenuItemExtraOption[]): MenuItemExtraOption[] {
    return value.filter((i) => i.isChecked);
  }
}
