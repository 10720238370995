import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyField } from '@ngx-formly/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
})
export class CustomCheckboxComponent extends FieldType {
}
