import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-custom-rating-input',
  templateUrl: './custom-rating-input.component.html',
  styleUrls: ['./custom-rating-input.component.scss'],
})
export class CustomRatingInputComponent extends FieldType {

}
