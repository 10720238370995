import { Component, OnInit, ViewChild } from "@angular/core";
import { AlertController, IonContent, IonSlides } from "@ionic/angular";
import {
  FieldArrayType,
  FormlyFieldConfig,
  FormlyFormBuilder
} from "@ngx-formly/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-page-group",
  templateUrl: "./page-group.component.html",
  styleUrls: ["./page-group.component.scss"]
})
export class PageGroupComponent extends FieldArrayType implements OnInit {
  @ViewChild("pgGroupSlider", { static: false })
  pgSlider: IonSlides;

  pgOpts = {
    centeredSlides: true,
    autoHeight: false,
    spaceBetween: 0,
    passiveListeners: true,
    slidesPerView: 1,
    onlyExternal: false
  };
  didInit: boolean;
  currentIndex: number = 0;
  constructor(builder: FormlyFormBuilder) {
    super(builder);

    this.didInit = true;
  }
  ngOnInit(): void {
    // setTimeout(() => {
    //   console.log("page slider", this.pgSlider);
    // }, 3000);
  }

  onSlidesDidLoad() {
    setTimeout(() => {
      if (this.pgSlider) {
        this.pgSlider.update();
        // console.log(this.pgSlider);
      }
    }, 3000);
  }

  onSlideDidChange() {
    this.pgSlider.getActiveIndex().then((index) => {
      this.currentIndex = index;
    });
  }

  slidePrev() {
    this.pgSlider.slidePrev();
  }

  slideNext() {
    this.pgSlider.slideNext();
  }

  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      if (field.formControl) {
        return field.formControl.valid;
      }
    }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }

  // async remove(index) {
  //   let counter = index + 1;
  //   let alert = await this.alertCtrl.create({
  //     header: this.translateService.instant('DELETE_GROUP'),
  //     subHeader: this.translateService.instant('DELETE_WARNING') + " " + this.field.name + " " + counter,
  //     buttons: [
  //       {
  //         text: this.translateService.instant('CANCEL'),
  //         role: 'cancel',
  //         handler: () => {
  //           // console.log('Cancel clicked');
  //         }
  //       },
  //       {
  //         text: this.translateService.instant('OK'),
  //         handler: () => {
  //           this.field.fieldGroup.splice(index, 1);
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }
}
